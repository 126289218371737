blockquote {
	padding: 0;
	position: relative;
	margin: var(--global--spacing-vertical) 0 var(--global--spacing-vertical) var(--global--spacing-horizontal);

	border: unset !important;
	border-left: 2px solid var(--wp--preset--color--secondary) !important;
	padding:2rem 1rem 0 1rem !important;

	> * {
		margin-top: var(--global--spacing-unit);
		margin-bottom: var(--global--spacing-unit);

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		font-family: var(--quote--font-family);
		font-size: var(--quote--font-size);
		font-style: var(--quote--font-style);
		font-weight: var(--quote--font-weight);
		line-height: var(--quote--line-height);
	}

	cite,
	footer {
		font-weight: normal;
		font-size: var(--global--font-size-xs);
		letter-spacing: var(--global--letter-spacing);
		color: #565658 !important;
		font-weight: 700;
	}

	&.alignleft,
	&.alignright {

		padding-left: inherit;

		p {
			font-size: var(--heading--font-size-h5);
			max-width: inherit;
			width: inherit;
		}

		cite,
		footer {
			font-size: var(--global--font-size-xs);
			letter-spacing: var(--global--letter-spacing);
		}
	}

	strong {
		font-weight: var(--quote--font-weight-strong);
	}

	&:before {
		content:'';
		@include pseudo-block(20px, 20px);
		background-image:url('../svg/sprite-quote.svg');
		left: 1rem !important; top:.25rem !important;
		// content: "\201C";
		// font-size: var(--quote--font-size);
	}

	.wp-block-quote__citation,
	cite,
	footer {
		font-style: normal;
		// color: var(--color-text);
		// font-size: var(--global--font-size-xs);
		// font-style: var(--quote--font-style-cite);
	}

	@include breakpoint-is(mobile-only) {
		padding-left: calc(0.5 * var(--global--spacing-horizontal));

		&:before {
			left: 0;
		}
	}
}
