/**
 * Site Structure
 *
 * - Set vertical margins and responsive widths on
 *   top-level wrappers and content wrappers
 * - `--global--width-content` is a responsive variable
 * - See: globals/_global-width-responsive.scss
 */

/**
 * Top Level Wrappers (header, main, footer)
 * - Set vertical padding and horizontal margins
 */

 .site-footer {
	 padding-top: var(--global--spacing-vertical);
	 padding-bottom: var(--global--spacing-vertical);
	 margin-left: auto;
	 margin-right: auto;
 }
 
 /**
 * Site-main children wrappers
 * - Add double vertical margins here for clearer hierarchy
 */
 
 .entry-content > * {
	 margin-bottom: calc(2 * var(--global--spacing-vertical));
 
	 &:first-child {
		 margin-top: 0;
	 }
 
	 &:last-child {
		 margin-bottom: 0;
	 }
 }
 
 /**
  * Set the default maximum responsive content-width
  */
 .default-max-width {
	 @extend %responsive-aligndefault-width;
 }
 
 /**
  * Set the wide maximum responsive content-width
  */
 .wide-max-width {
	 @extend %responsive-alignwide-width;
 }
 
 /**
  * Set the full maximum responsive content-width
  */
 .full-max-width {
	 @extend %responsive-alignfull-width-mobile;
	 @extend %responsive-alignfull-width;
 }
 
 /*
  * Block & non-gutenberg content wrapper children
  * - Sets spacing-vertical margin logic
  */
 .entry-content > *,
 [class*="inner-container"] > * { // using :where keeps specificity low.
 
	 margin-top: calc(0.666 * var(--global--spacing-vertical));
	//  margin-bottom: calc(0.666 * var(--global--spacing-vertical));
 
	 @include breakpoint-is(mobile-only) {
		 // margin-top: var(--global--spacing-vertical);
		 margin-bottom: var(--global--spacing-vertical);
	 }
 
	 &:first-child {
		 margin-top: 0;
	 }
 
	 &:last-child {
		 margin-bottom: 0;
	 }
 }
 
 
 
 
 /*
  * .entry-content children specific controls
  * - Adds special margin overrides for alignment utility classes
  */
 .entry-content > * {
 
	 &.alignleft,
	 &.alignright,
	 &.alignleft:first-child + *,
	 &.alignright:first-child + *,
	 &.alignfull.has-background {
		 margin-top: var(--global--spacing-vertical);
	 }
 
	 &:last-child,
	 &.alignfull.has-background {
		 margin-bottom: var(--global--spacing-vertical);
	 }
 
	 /* Reset alignleft and alignright margins after alignfull */
	 &.alignfull + .alignleft,
	 &.alignfull + .alignright {
		 margin-top: var(--global--spacing-vertical);
	 }
 }
 
 