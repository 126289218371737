.wp-block-social-links {
	margin-bottom: 1rem !important;
	
	.wp-social-link {
		background: unset !important;
		display: flex;
		align-items: center;
		justify-content: center;
		// width: 50px; height: 50px;
		position: relative;
		cursor: pointer;

		&:before {
			@include pseudo-block(100%, 100%, var(--wp--preset--color--secondary));
			border-radius: 50%; z-index:-1;
			transition: 400ms all $smooth;
			transform: scale(0);
		}
		&:hover {
			&:before { transform: scale(.8);}
			svg { fill:#000; }
		}
	}
	

	a:focus {
		color: var(--wp--preset--color--black);
	}

}
