.wp-block-video {

	figcaption {
		color: var(--wp--preset--color--black);
		font-size: var(--global--font-size-xs);
		margin-top: calc(0.5 * var(--global--spacing-unit));
		margin-bottom: var(--global--spacing-unit);
		text-align: center;
	}
}

* > figure > video {
	max-width: unset;
	width: 100%;
	vertical-align: middle;
}
