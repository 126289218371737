// Navigation 
.primary-navigation {
	position: fixed; z-index: 666;
	left:0; top: 0; width: 100%; height: var(--menu-height);
	max-width: $max_content_width; right:0; margin: 0 auto; 	//max_content_width;
	padding: 0 1rem;
	display: flex;
	justify-content: space-between;
	
	transition: 300ms all $smooth;	
	// border: 1px dotted red;
	--link-color : var(--wp--preset--color--black);
	
	&:before {
		@include pseudo-block(200vw, 100%, #fff );
		left:-50%; 	//calc((100vw - 1240px) / 2 * -1 );
		transition: 400ms all $smooth; z-index: -1;
	}

	

	&[nav-style='short'] {
		--menu-height: 72px;
		&:before { box-shadow: 0 0 24px rgba(0,0,0,.3); }
		.logo {
			.sprite.--full { display: none;}
			.sprite.--light { display: block;}
		}
		.logo-baseline { opacity: 0;}
		.menu-wrapper { transform: translateY(.5rem); }
		.mega-menu { transform: translateY(-1.25rem); }
		.search-wrapper {
			transform: translateY(1rem);
			input { opacity: 0; }
			form { border-color: transparent;}
			&:hover {
				input { opacity: unset; }
				form { border-color: var(--wp--preset--color--primary);}
			}
		}
	}


	.primary-menu-container {
		position: relative;
		flex: 0 1 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding-bottom: 1.75rem;
	}

	--logo-width: 128px;
	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 0rem;
		transition: 400ms all $smooth;
		.sprite {
			&.--full 	{ height: 145px; width: var(--logo-width); }
			&.--light 	{ height: 80px; width: var(--logo-width); display: none; }
			// @include breakpoint-is(laptop-down) { height: 50px; width: 210px; }
		}
	}

	.logo-baseline {
		position: absolute;
		top: 1.75rem; 
		left: calc( var(--logo-width) + 6rem);
		font-size: 2.5rem;
		font-weight: 300;
		color: var(--wp--preset--color--black);
		transition: 300ms all $smooth;
		strong {
			text-transform: uppercase;
			font-weight: 900;
			&:nth-of-type(1) { color: var(--wp--preset--color--primary); }
			&:nth-of-type(2) { color: var(--wp--preset--color--secondary); }
		}
	}

	.menu-wrapper {
		width: 100%;
    	// justify-content: space-between;
		padding-left: 2rem;
		padding-right: 3rem;
		& > li {
			// padding-top:2rem;
			// padding-bottom:2rem;
			margin-left: 2rem;
			border-radius: 1rem;
			transition: 400ms all $smooth;
			& > a, & > .yo-obf {
				height: 1rem;
				color: var(--link-color);
				font-weight: 800;
				text-transform: uppercase;
				padding-left:1rem !important;
				padding-right:1rem !important;
				text-decoration: none !important;
				svg { margin-left: .5rem; display: none;}
			}
			&:hover {
				@media (pointer: fine) {
					a, .yo-obf { color:#fff; }
					background-color: var(--wp--preset--color--primary-800);
				}
			}
			&.current-menu-item,
			&.--is-open {
				z-index: 3 !important;
				background-color: var(--wp--preset--color--primary-800);
				& > a, & > .yo-obf { 
					color:#fff;
				}
				.mega-menu {
					pointer-events: unset;
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
					transition: 600ms all $smooth;

				}
			}
		}
	}

	//generic
	ul { 
		padding: 0;
		li { 
			// position: relative;
			// list-style: none; 
			user-select: none;
			display: flex;
			& > a, & > .yo-obf  { 
				cursor: pointer;
				width:100%;
				text-decoration: none;
				display: flex;
				align-items: center;
				// font-size: .8rem;
				&:not(.logo) {
					line-height: 1;
					transition: 500ms all $smooth;
					position: relative;
					padding: 1rem .5rem;
				}
				svg { 
					transition: 800ms all $smooth; 
					pointer-events: none;
				}
			}
		}
	}
		
	//main menu
	.menu-wrapper {
		display: flex;
	}
	
	//mega menu
	.mega-menu {
		pointer-events: none;
		clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
		transition: 500ms all $smooth;
		position: absolute;  z-index: 1;
		left: calc(-128px - 1rem); top:0; width: 100vw;
		max-width: $max_content_width;
		// transform: translateX(-50%);
		background: linear-gradient(90deg, var(--wp--preset--color--secondary) 0%, var(--wp--preset--color--primary) 100% );

		margin-top: calc(var(--menu-height));
		padding: 1rem 1rem 1rem calc(128px + 3rem);
		display: flex;
		box-shadow: 0px 4px 8px 0px rgba(0, 47, 81, 0.16), 0px 2px 4px 0px rgba(0, 47, 81, 0.16);
		
		display: flex;
		flex-wrap: wrap;

		& > * {
			flex: 0 0 33%;
			max-width: calc(1680px / 3);
		}

		//
		ul { 
			padding: 1rem 1rem; 
			&.--last-childs {
				list-style: circle !important;
				padding: 0rem .5rem .5rem 1.5rem;
				color: #fff;
				li {
					display: list-item !important;
					a {
						text-transform: unset;
						font-weight: normal;
						padding: 1rem .5rem;
					}
				}
			}
		}
		//
		li {
			width: 100%;
			flex-direction: column;
			transition: 300ms background-color $smooth;
			a { 
				color: #fff; 
				text-transform: uppercase;
				font-weight: 800;
				position: relative;
				text-decoration: none !important;
				&:before {
					@include pseudo-block(100%, 1px, #fff);
					top:unset; bottom: 0;
					transform: scaleX(0);
					transform-origin: top left;
					transition: 500ms all $smooth;
				}
				&:hover { 
				   &::before { transform: unset; }
			   	}
			}
			// svg { 
			// 	fill: var(--wp--preset--color--primary-800);
			// }
			&.btn-close-mega-menu {
				position: absolute; top:3rem; right: 3rem;
				width: 32px; height: 32px;
				border-radius: 50%;
				border: 1px solid #fff;
				display: inline-block;
				flex: unset;
				cursor: pointer;
				transition: 300ms all $smooth;
				svg { fill: #fff; transform: translate(-1px, -1px); }
				&:hover {
					background-color: #fff;
					svg { fill:var(--wp--preset--color--primary); }
				}
			}
		}
	}
}

//

	.button--search-open,
	.button--search-submit {
		width: 32px; height: 32px;
		display: flex; 
		justify-content: center;
		align-items: center;
		background: var(--wp--preset--color--primary);
		border-radius: 4px;
		svg { fill: #fff;}
	}

// Mobile menu toggles
.menu-button-container {
	display: none;
	position: absolute; z-index: 33;
	justify-content: flex-end;
	align-items:center;
	right: 0; 
	// width: calc(100% - 1rem); 
	height: 100%;
	
	@include breakpoint-is(desktop-down) {
		display: flex;
	}
	
	.button--toggle-menu {
		border: unset;
		background-color: var(--wp--preset--color--secondary) !important;
		height: 32px;
		margin: .35rem 1rem 0 0;
		padding: 0 1rem;
		position: relative;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 16px;
		label {
			color: #fff;
			font-weight: 700;
			margin-right: 2rem;
		}
		& > * {
			user-select: none;
			pointer-events: none;
		}
		.dropdown-icon {
			// border: 1px salmon dotted;
			position: absolute; top:.75rem; right:1.25rem;
			width:16px; height:16px;
			display: inline-block;
			span { 
				display: inline-block;
				width: 0%; height: 2px; background-color: #fff;
				position: absolute; right:0;
				transition: 300ms all $smooth;
				transform-origin: top left;
				border-radius: 2px;
			}
			&.open {
				span:nth-child(1) { width:12px; transition-delay: 200ms; top:0px;}
				span:nth-child(2) { width:12px; transition-delay: 300ms; top:4px;}
				span:nth-child(3) { width:12px; transition-delay: 350ms; top:8px;}
			}
			//
			&.close {
				// border: dotted 1px red !important;
				span:nth-child(1) { transition-delay: 0ms; transform:rotate(45deg); 	left:6px; top:0px;}
				span:nth-child(2) { transition-delay: 100ms; transform:rotate(-45deg);	left:5px; bottom:5px;}
			}
		}
		&[aria-expanded=true] {
			.dropdown-icon.open span 	{ 
				width: 0%;
				&:nth-child(1) { transition-delay: 0ms; }
				&:nth-child(2) { transition-delay: 100ms; }
				&:nth-child(3) { transition-delay: 150ms; }
			}
			.dropdown-icon { border-color: var(--wp--preset--color--primary);}
			.dropdown-icon.close span 	{ 
				width: 13px; 
				&:nth-child(1) { transition-delay: 200ms; }
				&:nth-child(2) { transition-delay: 350ms; }
			}
		}
	}
}


			//this is the responsive arrow for mobile navigation
			// .arrow-responsive {
			// 	display: none;
			// 	width: 32px; height: 32px;
			// 	position: relative;
			// 	cursor: pointer;
			// 	transform: rotate(90deg);
				
			// 	&::before {
			// 		@include pseudo-block(100%, 100%);
			// 		border:1px solid #000;
			// 		opacity: 0;
			// 	}
			// 	&::after {
			// 		@include pseudo-block(12px, 12px);
			// 		border-top: 2px solid var(--wp--preset--color--primary);
			// 		border-right: 2px solid var(--wp--preset--color--primary);
			// 		transform: translate(10px, 13px) rotate(-45deg);
			// 	}
			// }




@include breakpoint-is(desktop-down) {
	
	.primary-navigation {

		.wp-block-buttons { display: none; }

		.logo-baseline {
			padding-right: 128px;
			font-size: 1.5rem;
			top:40%
		}
		
		.primary-menu-container { 
			order: 3;
			width: 100%;
			height: calc(100vh - var(--menu-height));
			z-index:22;
			transition: 400ms all $smooth;
			position: absolute; 
			top: var(--menu-height); 
			left:0;
			clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
			background-color: #fff;
			// border-radius: 10px;
			overflow: auto;
			flex-direction: column;
			justify-content: flex-start;
			.search-wrapper { order:1;}
			.menu-wrapper {
				order: 2;
				width:100%; height: 100%;
				transition: 600ms all $smooth;
				position: relative;
				flex-direction: column;
				align-items: flex-start;
				padding: 0 1rem;
				& > li { 
					display: flex;
					flex-direction: column;
					justify-items: center;
					align-items: center;
					opacity: 0;
					padding: 0;
					margin: 0;
					transition: 800ms all $smooth;
					width: 100%;
					br { display: none; }
					
					& > a, & > .yo-obf {
						padding: 1rem 0 !important;
						margin: .5rem 0;
						// height: .5rem;;
					}
					&.current-menu-item {
						padding-left: 1rem !important;
					}
					a, .yo-obf { 
						font-size: 1rem;
						padding-left: 1rem !important;
					}
					// &:not(:last-child) {
					// 	&:before {
					// 		// @include pseudo-block(100%, 1px, var(--wp--preset--color--primary-50) );
					// 		top: unset; bottom: 0;
					// 		transition: 1000ms all $smooth;
					// 		transform-origin: top left;
					// 		transform: scaleX(0);
					// 	}
					// }
					& > * {
						svg { 
							display: flex; fill: var(--wp--preset--color--secondary);
							position: absolute; right: 1rem;
						}
					}
					&:nth-child(1) { transition-delay: 200ms; &:before { transition-delay: 300ms;} }
					&:nth-child(2) { transition-delay: 300ms; &:before { transition-delay: 400ms;} }
					&:nth-child(3) { transition-delay: 370ms; &:before { transition-delay: 470ms;} }
					&:nth-child(4) { transition-delay: 420ms; &:before { transition-delay: 520ms;} }
					&:nth-child(5) { transition-delay: 470ms; &:before { transition-delay: 570ms;} }
					&:nth-child(6) { transition-delay: 500ms; &:before { transition-delay: 600ms;} }
					
					&.--is-open {
						background-color: unset !important;
						margin-bottom: 1rem;
						// padding-bottom: .5rem !important;
						svg { fill: var(--wp--preset--color--primary); }
						& > a { color: var(--wp--preset--color--primary);}
						.mega-menu { 
							overflow: hidden;
							height: fit-content; 
							padding: 1rem 1rem;
						}
					}
				}

				.mega-menu {
					position: unset !important;
					// background-color: var(--wp--preset--color--secondary-50);
					margin-top: unset; top: unset; left: unset; transform: unset;
					width: 100%; height:0; 
					padding:0;
					border-radius: 1rem;
					& > * {
						flex: 0 0 100%;
					}
					ul {
						padding: 0;
						&.--last-childs { margin-left: 2rem; }
					}
					.btn-close-mega-menu { display: none !important; }
				}
			}
			
			&.is-open {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
				.menu-wrapper {
					& > li { 
						opacity: unset; transform: unset;
						&:before { transform: unset;}
					}
				}
			}
		}
	}
}

@include breakpoint-is(tablet-down) {
	.primary-navigation {
		--menu-height: 128px;
		--logo-width: 96px;
		.logo {
			width: var(--logo-width);
		}
		.logo-baseline { 
			left: calc( var(--logo-width) + 2rem);
			font-size: 1.25rem;
		}
		
	}
}

.menu-blackout {
	background-color: #000;
	opacity: .3;
	width: 100%; height: 100%;
	position: fixed; top:0; left: 0;
	z-index: 555;
	display: none;
	cursor: pointer;
}

body.menu-is-open {
	overflow: hidden;
	.menu-blackout { display: unset;}
	.entry-content {
		pointer-events: none;
		overflow: hidden;
		filter: saturate(0);
	} 
}