h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	// clear: both;
	position: relative;
	// color: var(--color-heading, inherit);

	font-family : var(--font-headings);

	&:not(:first-child) { margin-top: 1rem !important; }
	margin-bottom: .75rem !important;

	font-weight: 300;

	strong { 
		font-weight: 800;
	}
	
	&.is-style-vertical-line {
		padding-left: 1rem;
		&:before {
			@include pseudo-block(4px, 100%, var(--wp--preset--color--secondary));
			top: 0;
			border-radius: 2pxs;
		}
	}
	
	&.is-style-story {
		padding-left: 1rem;
		&:before {
			@include pseudo-block(4px, 100%, var(--wp--preset--color--secondary));
			top: 0;
			border-radius: 2pxs;
		}
		font-size: 1.15rem;
		strong {
			font-size: 2.5rem;
			color: var(--wp--preset--color--primary);
			padding-bottom: .25rem;
			display: inline-block;
		}
	}
	// &.is-style-horizontal-line {
	// 	padding-left: 2.5rem;
	// 	&:before {
	// 		@include pseudo-block(2rem, 2px, var(--wp--preset--color--secondary));
	// 		top: calc(50% - 1px);
	// 		border-radius: 1px;
	// 	}
	// }
}

h1,
.h1 {
	font-size: var(--heading--font-size-h1);
	line-height: var(--heading--line-height-h1);
}

h2,
.h2 {
	font-size: var(--heading--font-size-h2);
	line-height: var(--heading--line-height-h2);
}

h3,
.h3 {
	font-size: var(--heading--font-size-h3);
	line-height: var(--heading--line-height-h3);
}

h4,
.h4 {
	font-size: var(--heading--font-size-h4);
	line-height: var(--heading--line-height-h4);
}

h5,
.h5 {
	font-size: var(--heading--font-size-h5);
	line-height: var(--heading--line-height-h5);
}

h6,
.h6 {
	font-size: var(--heading--font-size-h6);
	line-height: var(--heading--line-height-h6);
}
