/**
 * Entry Content
 */

.entry-content {
	padding:  calc(var(--menu-height) + 0rem) 0 2rem 0;
	@include breakpoint-is(desktop-down) {
		padding:  calc(var(--menu-height) + -3rem) 0 2rem 0;
	}
	width:100%;
	transition: 300ms all $smooth;

	// hooko to remove FUC***G blank space after GravityForm
	overflow: hidden;

	//remove bottom padding if last child has a background color
	&:has(*.has-background:last-child) { padding-bottom: 0 !important; }
	//remove bottom margin if last child has a background color
	& > *:last-child.has-background { margin-bottom: 0 !important; }
	
	// @include breakpoint-is(desktop-down) { padding-top: 50px;}

	& > * {
		&.alignfull:not(.wp-block-cover):not(.has-background) {
			// border: 1px dotted blue !important;
			// max-width: 100%;	//$max_content_width; // changed 2024
			&:not(.yo-product-content) { max-width: $max_width;}
			@include breakpoint-is(wide) { width: calc(100% - var(--content-horizontal-padding)*2); }
		}
		&.alignwide {
			// border: 1px dotted red !important;
			max-width: $wide_width;
			width: calc(100% - var(--content-horizontal-padding));
		}
		&:not(.alignfull):not(.alignwide) {
			// border: 1px dotted greenyellow !important;
			max-width: $default_width;
			width: calc(100% - var(--content-horizontal-padding));
		}
	}
	p {
		word-wrap: break-word;
	}

	// Overwrite iframe embeds that have inline styles.
	> iframe[style] {
		margin: var(--global--spacing-vertical) 0 !important;
		max-width: 100% !important;
	}

	// Classic editor audio embeds.
	.wp-audio-shortcode {
		@extend %responsive-aligndefault-width;
	}

}