.wp-block-file {

	// Undo Gutenberg hover defaults
	a.wp-block-file__button:active,
	a.wp-block-file__button:focus,
	a.wp-block-file__button:hover {
		opacity: inherit;
	}

	a.wp-block-file__button {
		display: inline-block;
	}
}
