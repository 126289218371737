.yo-block-accordeon {
  background-color: unset !important;
  margin-bottom: calc(var(--global--spacing-horizontal) * 1);

    //add border radius
    &:not(.--with-tabs) .accordeon__row {
      &:first-child { 
        border-top-left-radius: var(--yo-radius);
        border-top-right-radius: var(--yo-radius);
      }
      &:last-child { 
        border-bottom-left-radius: var(--yo-radius);
        border-bottom-right-radius: var(--yo-radius);
      }
    }
    &:not(.--with-tabs) .accordeon__row {
      border-bottom-left-radius: var(--yo-radius);
      border-bottom-right-radius: var(--yo-radius);
    }

    //when not multilines, disable label button
    &[multi-lines="0"] .accordeon__label[aria-expanded="true"] {
      cursor: default !important;
      pointer-events: none;
    }

    .accordeon {
      width:100%;
      margin: 0 0 2rem 0; padding: 0;
      display: flex;
      flex-direction: column;

    .accordeon__row {
      display: inline-block;
      list-style: none;
      position: relative;
      
      border-radius: var(--yo-radius);
      margin: 0 0 .5rem 0;
      max-width: 740px;
      transition: 300ms all ease-out;
      
      &:last-child { margin-bottom: 0; }
      
      background:var(--wp--preset--color--grey-50);

      // manage custom colors
      &.has-text-color .icon-minus-plus {
        &:before, &::after { background-color: currentColor !important;}
      }
      &.has-background .icon-minus-plus {
        &:before, &::after { background-color: #fff !important;}
      }

      //
      &.--selected .accordeon__label { color: #000 !important; }

      //DEFINE ACCORDEON
      .accordeon__label {
        & > * { pointer-events: none; }
        user-select: none;
        cursor: pointer;
        margin: 0;
        padding: 1rem 2rem;
        line-height: 1;
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--wp--preset--color--primary);

        transition: 200ms color ease-out;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        transition: 200ms all ease-out;
        
        .box__label, .box__icon { position: relative; z-index:3; }
        .box__label { 
            padding-right: 1rem;
            line-height: 1.5;
        }
        .box__icon {
            transition: 200ms all ease-out;
            .icon-minus-plus {
                width: 24px; height: 24px;
                transform-origin: center center;
                transition: 500ms all $bounce;
                
                &:before, &:after {
                    transition: 800ms all $bounce;
                }
                &:before {
                    @include pseudo-block(10px, 2px, var(--wp--preset--color--primary));
                    top:calc(50% - 1px); left:calc(50% - 5px);
                    // transform: rotate(45deg);
                } 
                &:after {
                    @include pseudo-block(2px, 10px, var(--wp--preset--color--primary));
                    top:calc(50% - 5px); left:calc(50% - 1px);
                    // transform: rotate(-45deg);
                    // transform-origin: bottom left;
                }
            }
        }
        &[aria-expanded="true"] {
          
            .icon-minus-plus {
                &:after  { transform: scale(0); }
            }
        }
      }
      
      // Accordeon row is filtered
      &.remove-from-filter { display: none; }

      // Accordeon row is hover
      &:not(.--selected):hover { 
        filter: brightness(.9);
        // .icon-minus-plus { &:before, &:after { background-color: var(--wp--preset--color--secondary);} }
      }
      
      .accordeon__content {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding: 0 2rem;
        transition: 1200ms max-height ease-in, 200ms all ease-out;
        ul { margin: 0; padding: 0 0 0 1rem;}
        p, li {
            &:last-child { margin-bottom: 0; }
        }
        &:not([hidden]) {
          opacity: 1;
          max-height: 1000px;
          transition: 2800ms max-height ease-out, 200ms opacity ease-out;
          padding-bottom: 1rem;
        }
        &[hidden] { display: block;} //to overwrite normalize.css
      }
    }
  }

  .accordeon__filters {
    
    ul {
      margin: 0;padding: 0 1rem 0 0;
      display: flex;
      li {
        list-style: none;
        cursor: pointer;
        user-select: none;
        font-family: var(--font-base);
        font-size: 1rem;
        text-align: center;
        line-height: 1rem;
        transition: 300ms all $smooth;
        position: relative; z-index: 2;
        padding: 0 1rem 1rem 1rem;
        margin: 0 0 .5rem;
        &:not(:last-child) { margin-right: 1rem;}
  
        &:after {
          @include pseudo-block(100%, 4px, var(--wp--preset--color--grey));
          top:unset; bottom: 0;
          transform: scaleX(0);
          transform-origin: top left;
          transition: 400ms all $smooth;
        }
        &:not(.active):hover {
          &:after { transform: unset;}
        }
        &.active {
          font-weight: 700;
          pointer-events: none;
          &:after { transform: unset; background-color: var((--wp--preset--color--secondary));}
        }
        &:before { display: none;}
      }
    }
  }
  
  
  @include breakpoint-is(mobile-only) {
    .accordeon__label,
    .accordeon__content {
      padding-left: 1rem !important; 
      padding-right: 1rem !important; 
    }
    .block-accordeon { flex-direction: column;}
    .accordeon__filters + .accordeon,
    .accordeon__filters {
      flex: 0 0 100%;
    }
    .accordeon__filters {
      margin:0 0 0 0;
      ul { 
        flex-wrap: wrap;
        li { padding: 0 0 .5rem 0;}
      }
    }
  }


  //
  //unset to be able to support colors changes from admin
  .wp-block-group__inner-container {
      background-color: unset !important;
  }

}