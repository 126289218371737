.grid {
    // margin-left:auto !important;
    // margin-right:auto !important;
    max-width: unset !important;
    position: relative;
    display: grid;
    
    &:not(.--list):not(.grid--list) {
        grid-template-columns: repeat(var(--grid-columns), 1fr );
        grid-gap: 1.5rem;
        @include breakpoint-is(table) { grid-gap: 1rem; }
    }
    //1 columns 
    &.grid--1 { 
        --grid-columns: 1; 
        gap: 0;
    }

    //2 columns 
    &.grid--2 { --grid-columns: 2; 
        @include breakpoint-is(tablet-down) { --grid-columns: 1; }
    }

    //3 columns  
    &.grid--3 { --grid-columns: 3; 
        @include breakpoint-is(laptop-down) { --grid-columns: 2; }
        @include breakpoint-is(tablet-down) { --grid-columns: 1; }
    }
    
    //4 columns
    &.grid--4 { --grid-columns: 4; 
        @include breakpoint-is(laptop-down) { --grid-columns: 2; }
        @include breakpoint-is(tablet-down) { --grid-columns: 1; }
    }
    
    //5 columns  
    &.grid--5 { --grid-columns: 5; 
        @include breakpoint-is(laptop-down) { --grid-columns: 2; }
        @include breakpoint-is(tablet-down) { --grid-columns: 1; }
    }
    
    //6 columns  
    &.grid--6 { --grid-columns: 6; 
        @include breakpoint-is(wide-only)       { --grid-columns: 5; }
        @include breakpoint-is(desktop-only)    { --grid-columns: 4; }
        @include breakpoint-is(tablet-down)     { --grid-columns: 1; }
    }


    &.--list {
        grid-gap: 0rem;
        display: flex;
        flex-direction: column;

        // @include breakpoint-is(desktop) {
        //     --height-max : 400px;
        //     grid-template-columns: repeat(3, 1fr);
        //     grid-template-rows: repeat(minmax(1, 6), 200px);
        //     grid-auto-flow: dense;
            
        // }
        // --  --
        @include breakpoint-is(desktop-down) {
            display: flex;
            flex-wrap: wrap;
            & > * {
                flex: 1 1 50%;
            }
        }
    }

    
}


body.search-results .grid .card.--ressource {
    height: unset;
    .card__infos {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        .card__ressource_type { flex: 0 1 100%; }
        .card__title { flex: 0 1 100%; padding: .5rem 0; font-weight: 700; }
        .card__date { flex: 0 1 calc(100% - 80px); text-align: right; }
    }
}