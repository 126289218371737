.wp-block-separator,
hr.wp-block-separator {
	clear: both;
	opacity: 1;
	border: none;

	margin : 2rem auto;
	position: relative;
	
	&:not(.has-background) {
		color: rgba(255, 255, 255, .3);
		background-color: rgba(255, 255, 255, .3);
	}
	
	&:not(is-style-dots):not(is-style-wide) {
		width: 50% !important; height:4px !important;
	}

	&.is-style-wide {
		width: 100% !important; height: 4px !important;
	}
	
	&.is-style-dots {
		width: 100% !important; height: 2px !important;
		background-color: unset !important;
		margin-bottom: 3rem;
	}

	// -- responsive --
	@include breakpoint-is(desktop-down) {
		width: 2px !important;
	}

	

	// &[style*="text-align:right"],
	// &[style*="text-align: right"] {
	// 	border-right-color: var(--separator--border-color);
	// }

	// &:not(.is-style-dots) {
	// 	max-width: var(--responsive--aligndefault-width);
	// }

	// [data-align="full"] > &,
	// [data-align="wide"] > & {
	// 	max-width: inherit;
	// }

	// .has-background &,
	// [class*="background-color"] &,
	// [style*="background-color"] &,
	// .wp-block-cover[style*="background-image"] & {
	// 	border-color: currentColor;
	// }
}

// -- responsive --
@include breakpoint-is(desktop-down) {
	 
}