
.yo-block-grid-push {
   .wrapper {
    display: flex;
    flex-wrap: wrap;
        &.items-5 {
            .push:nth-child(1),
            .push:nth-child(2)  { --width: 50%; --height: 60vh;}
            .push:nth-child(3), .push:nth-child(4),
            .push:nth-child(5)  { --width: 33.33%; --height: 40vh;}
            @include breakpoint-is(tablet-down) {
                .push:nth-child(1),
                .push:nth-child(2)  { --width: 50%; --height: 40vh;}
                .push:nth-child(3), .push:nth-child(4)  { --width: 50%; --height: 35vh;}
                .push:nth-child(5)  { --width: 100%; --height: 30vh;}
            }
        }
        &.items-4 {
            .push:nth-child(1),
            .push:nth-child(2)  { --width: 50%; --height: 60vh;}
            .push:nth-child(3), 
            .push:nth-child(4)  { --width: 50%; --height: 40vh;}
            @include breakpoint-is(tablet-down) {
                .push:nth-child(3), .push:nth-child(4)  { --width: 50%; --height: 40vh;}
            }
        }
        &.items-3 {
            .push:nth-child(1)  { --width: 100%; --height: 60vh;}
            .push:nth-child(2), 
            .push:nth-child(3)  { --width: 50%; --height: 40vh;}
        }
        &.items-2 {
            .push:nth-child(1),
            .push:nth-child(2)  { --width: 50%; --height: 100vh;}
        }
        &.items-1 {
            .push:nth-child(1)  { --width: 100%; --height: 100vh;}
        }
        &:hover {
            a { opacity: .7;}
        }
    }
    .push {
        background-color: #000;
        position: relative;
        flex: 0 0 var(--width);
        height : calc(var(--height) - var(--menu-height)/2);
        --width-icon : 80px;
        a {
            display: inline-flex;
            height: 100%; width: 100%;
            transition: 500ms all $smooth;
            &:hover { opacity: unset}
        }
        .background     { 
            @include object-fit(cover);
        }
            .overlay {
                z-index: 30;
                background-color: rgba(0,0,0,.3);
                position: absolute; 
                top: 0; left:0; width: 100%; height: 100%;
                display: none;
            }
        img {
            z-index: 20;
            position: relative;
            
        }
        .label {
            z-index: 40;
            position: absolute;
            margin: 0 auto;
            left: 0; right: 0; 
            top: calc(50% - 1rem - var(--width-icon)/2 );
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon { 
                width: var(--width-icon);
                height: var(--width-icon);
                margin-bottom: 1rem;
                img { @include object-fit(contain); }
            }

            span {
                color:#000;
                height: 2rem;
                font-weight: 700;
                line-height: 1;
                border-radius: 1rem;
                background-color: rgba(255,255,255,.8);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 1rem;
                width: fit-content;
                text-align: center;
            }
        }
    }
} 