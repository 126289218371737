body:not(.wp-admin) .entry-content {

    //
    h1, h2, h3, h4, h5, h6, h3, p:not(.noa), ul:not([class*=noa]){ // ul:not(.pages):not(.fitler) {
        opacity: 0; transform: translateY(5px);
        transition: 300ms opacity ease-out, 1000ms transform $smooth;
    }
    figure:not([class*=noa]) { // ul:not(.pages):not(.fitler) {
        opacity: 0;
        transition: 500ms opacity ease-out;
    }

    // *:not(.yo-block-gallery) *.animate.--reveal { 
    //     opacity: unset !important; transform: unset !important;
    // }
    
    // block with a background color
    .wp-block-group.has-background {
        opacity: 0;
    }
    // block buttons
    .wp-block-buttons {
        .wp-block-button { 
            opacity: 0; transition: 800ms opacity $smooth; 
        }
    }
    // block image
    .wp-block-image {
        opacity: 0; transition: 850ms opacity $smooth; 
    }

    // @include add_delay(500ms);
    //text_image
    .wp-block-media-text {
        .wp-block-media-text__content { 
            & > *.has-background {
                &:before { 
                    transform: scaleY(0);
                    transform-origin: top center;
                    transition: 700ms all $smooth;
                }
                background-color: unset;
                & > * { @include add_delay(300ms, 400ms); }
            }
        }
        .wp-block-media-text__media {
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            transition: 1300ms all $smooth, 1000ms filter $smooth;
            filter: blur(32px);
            img {
                transition: 900ms all $smooth 300ms;
            }
        }
        &.--reveal {
            .wp-block-media-text__content > *:before,
            .wp-block-media-text__content > * { opacity: unset; transform: unset;}
            .wp-block-media-text__media {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                filter: unset;
            }
        }
    }

    // group
    .wp-block-group.has-background {
        opacity: 0; transition: 300ms all $smooth;
    }
    
    // separator
    .wp-block-separator {
        transition: 400ms transform $smooth;
        transform: scaleX(0);
    }

    // quote
    .wp-block-quote {
        opacity: 0;
        &.--reveal { 
            &, &:before { opacity: unset; transform: unset;}
        }
        &:before {
            // transform-origin: top center; transform: scaleY(0); 
            transform: translateY(-.5rem); opacity: 0;
            transition: 700ms all $smooth;
        }
    }

                                                        // // cover
                                                        // .wp-block-cover {
                                                        //     overflow: hidden;
                                                        //     clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                                                        //     transition: 1000ms clip-path $smooth;
                                                            
                                                        //     &:after {
                                                        //         @include pseudo-block(100%, 100%, var(--wp--preset--color--primary));
                                                        //         transform-origin: left bottom;
                                                        //         transition: 1600ms transform $smooth, 2000ms opacity ease-out;
                                                        //     }
                                                        //     .wp-block-cover__image-background {
                                                        //         transform-origin: center center;
                                                        //         transform: scale3d(1.1, 1.1, 1.1);
                                                        //         transition: 1600ms all $smooth;
                                                        //     }
                                                        //     &.--reveal { 
                                                        //         clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                                                        //         &:after { transform: scaleY(0); opacity: 0; }
                                                        //         .wp-block-cover__image-background { opacity: unset; transform: unset; }
                                                        //         .post_metas { transform: unset; opacity: unset}
                                                        //     }
                                                        // }
    
    //gallery
    .yo-block-gallery {
        .flickity-page-dots li {
            @include add_delay(300ms, 300ms);
            transform:scale(0); transition: 500ms all $smooth;
        }
        .wp-block-image { opacity: 1 !important;}
        &.--reveal {
            .flickity-slider { opacity: unset;}
            .flickity-page-dots li { transform: unset;}
        }
    }

   
////////////////////////////////////////////////////////
// default animate
////////////////////////////////////////////////////////
    .animate {
        transition: 300ms opacity $smooth, 800ms transform $smooth;
        
        &.--from-opacity { opacity: 0;}
        
        &.--from-top    { opacity: 0; transform: translateY(-10px); }
        &.--from-bottom { opacity: 0; transform: translateY(10px); }
        &.--from-left   { opacity: 0; transform: translateX(-15px); }
        &.--from-right  { opacity: 0; transform: translateX(15px); }
    
        &.--reveal      { transform: unset; opacity: unset;}
    }
}
