.wp-block-quote {
	border-left: none;
	padding: 3rem 2rem 2rem 2rem;
	// &:before {
	// 	// @include pseudo-block(3px, 100%, var(--wp--preset--color--primary));
	// }

	.wp-block-quote__citation,
	cite,
	footer {

		.has-background &,
		[class*="background-color"] &,
		[style*="background-color"] &,
		.wp-block-cover[style*="background-image"] & {
			color: currentColor;
		}
	}

	
	/**
	 * Add quote
	 */
	&::before { 
		font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
		left: 2.5rem; top:1rem;
		font-size: 4rem;
		line-height: 1;
		color: currentColor;
	}
	padding-left: 3rem;

	/**
	 * 
	 */
	&:not(.is-style-plain) {
		background: unset !important;
		border: 2px solid var(--wp--preset--color--primary);

		&.has-background {
			&:before, p { color: var(--local-color); }
			border-color : var(--local-color);
			&.has-primary-background-color { --local-color : var(--wp--preset--color--primary); }
			&.has-secondary-background-color { --local-color : var(--wp--preset--color--secondary); }
			&.has-tertiary-background-color { --local-color : var(--wp--preset--color--tertiary); }
			&.has-primary-light-background-color { --local-color : var(--wp--preset--color--primary-light); }
			&.has-secondary-light-background-color { --local-color : var(--wp--preset--color--secondary-light); }
			&.has-tertiary-light-background-color { --local-color : var(--wp--preset--color--tertiary-light); }
			&.has-primary-dark-background-color { --local-color : var(--wp--preset--color--primary-dark); }
			&.has-secondary-dark-background-color { --local-color : var(--wp--preset--color--secondary-dark); }
			&.has-tertiary-dark-background-color { --local-color : var(--wp--preset--color--tertiary-dark); }
			
			&.has-grey-background-color { --local-color : var(--wp--preset--color--grey-700); }
			&.has-grey-light-background-color { --local-color : var(--wp--preset--color--grey-700); }
			&.has-grey-dark-background-color { --local-color : var(--wp--preset--color--grey-700); }
		}
	}
	
	&.is-style-plain {
		
	}

	/**
	 * Block Options
	 */
	&.has-text-align-right {
		margin: var(--global--spacing-vertical) var(--global--spacing-horizontal) var(--global--spacing-vertical) auto;
		padding-right: 0;
		border-right: none;

		// Hide the left aligned quote.
		&:before {
			display: none;
		}

		// Align the quote left of the text.
		p:before {
			content: "\201D";
			font-size: var(--quote--font-size);
			font-weight: normal;
			line-height: var(--quote--line-height);
			margin-right: 5px;
		}
	}

	&.has-text-align-center {
		margin: var(--global--spacing-vertical) auto;

		&:before {
			display: none;
		}
	}

	&.is-large,
	&.is-style-large {
		padding-left: 0;
		padding-right: 0;

		/* Resetting margins to match _block-container.scss */
		margin-top: var(--global--spacing-vertical);
		margin-bottom: var(--global--spacing-vertical);

		p {
			font-size: var(--quote--font-size-large);
			font-style: var(--quote--font-style-large);
			line-height: var(--quote--line-height-large);
		}

		&:before {
			font-size: var(--quote--font-size-large);
			line-height: var(--quote--line-height-large);
			left: calc(-1 * var(--global--spacing-horizontal));
		}

		&.has-text-align-right {

			// Hide the left aligned quote.
			&:before {
				display: none;
			}

			// Align the quote left of the text.
			p:before {
				content: "\201D";
				font-size: var(--quote--font-size-large);
				font-weight: normal;
				line-height: var(--quote--line-height-large);
				margin-right: 10px;
			}
		}

		.wp-block-quote__citation,
		cite,
		footer {
			color: var(--color-heading);
			font-size: var(--global--font-size-sm);
		}

		@include breakpoint-is(mobile-only) {
			padding-left: var(--global--spacing-horizontal);

			&:before {
				left: 0;
			}

			&.has-text-align-right {
				padding-left: 0;
				padding-right: var(--global--spacing-horizontal);

				&:before {
					right: 0;
				}
			}

			&.has-text-align-center {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	@include breakpoint-is(mobile-only) {

		&.has-text-align-right {
			padding-left: 0;
			padding-right: calc(0.5 * var(--global--spacing-horizontal));

			&:before {
				right: 0;
			}
		}

		&.has-text-align-center {
			padding-left: 0;
			padding-right: 0;
		}
	}

	//
	p {
		font-size: 1rem;
		// font-family: var(--font-headings);
		// font-style: italic;
		font-weight: 300;
	}
	cite {
		// font-weight: bold;
		font-size: .8rem;
		padding-left: 0;
		position: relative;
		color: currentColor;
	}
}
