.yo-block-pagination {
    margin: 2rem auto 1rem auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	position: relative;

	&.--disabled { 
		pointer-events: none;
		span, a { 
			color: #e1e1e1 !important; 
			&:before { display: none;}
		}
		//
		&:before, &:after {
			@include pseudo-block(10px, 10px); z-index: 21;
			border-radius: 50%; top: 14px;
			transform-origin: center;
		}
		&:before 	{ 
			background-color: var(--wp--preset--color--primary-100); 
			animation: wait 1s .1s infinite; 
			left: calc(50% - 10px);
		}
		&:after 	{ 
			background-color: var(--wp--preset--color--primary-100); 
			animation: wait 1s .5s infinite; 
			left: calc(50% + 10px);
		}
		@keyframes wait {
			0%    { transform: scale(1);}
			40%   { transform: scale(.2);}
			100%  { transform: scale(1);}
		}
	}

    .yo-pagination {
      display: flex;
      padding-left: 0;
      
      li { 
		// opacity: 0;
        list-style: none; 
        margin:0; padding:0;
        width: 40px; height: 40px;
        
		//add specific radius for first & last 
		&:nth-child(2) .page-number { border-radius: var(--yo-radius) 0 0 var(--yo-radius);}
		&:nth-last-child(2) .page-number { border-radius: 0 var(--yo-radius) var(--yo-radius) 0;}

        // transition: 600ms all $smooth; @include add_delay(300ms); 
      }
    //   &.--reveal li { opacity: unset; transform: unset; }
	  
    }

    .page-number {
		text-decoration: none !important;
		padding: 0;
		user-select: none;
		position: relative; z-index: 3;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 1rem;
		font-weight: 700;
		color:#565658;
		& > * { pointer-events: none; } //disabled inside elements

		&:hover:not(.dots) { 
			&:before { background-color: var(--wp--preset--color--grey-100) !important; transform: scale(1);}
			&.prev:before, &.next:before { background-color: #fff !important;  transform: scale(.85); }
		}

		&:not(.prev):not(.next) { 
			background-color: var(--wp--preset--color--primary-25);
		}
		
		&:before {
			@include pseudo-block(100%, 100%, transparent);
			z-index: -1;
			border-radius: calc(var(--yo-radius) / 4);
			transition: 300ms all ease-out;
			transform-origin: center;
		}

		&[aria-current=page] { 
			pointer-events: none;
			color: #fff;
			&:before { background-color: var(--wp--preset--color--secondary) !important;} 
		}
		&.next, &.prev {
			// border: 1px dotted blue;
			&:after {
				@include pseudo-block(12px, 12px); 
				top:14px;
				border-top:2px solid var(--wp--preset--color--primary);
				border-right:2px solid var(--wp--preset--color--primary);
				transform-origin: center;
				transition: 400ms all $smooth;
			}
			&.prev:after { transform: rotate(-135deg); left:16px; }
			&.next:after { transform: rotate(45deg); left: 12px;}
			&:hover:after { border-color: var(--wp--preset--color--secondary);}
		}
		&.empty { width: 40px;}
  	}
}