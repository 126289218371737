// Gutenberg background-color options
.has-background {

	a,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: currentColor;
	}
}

.has-black-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-primary: var(--wp--preset--color--black, #000000);
		color: var(--local--color-primary);
	}
	color: var(--wp--preset--color--black);
}

.has-gray-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-primary: var(--wp--preset--color--grey, #000000);
		color: var(--local--color-primary);
	}
	color: var(--wp--preset--color--grey);
}

.has-dark-gray-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-primary: var(--wp--preset--color--grey-700, #000000);
		color: var(--local--color-primary);
	}
	color: var(--wp--preset--color--grey-700);
}
 

.has-primary-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-primary: var(--wp--preset--color--primary, #FFFFFF);
		color: var(--local--color-primary);
	}
	--yo--color: var(--wp--preset--color--primary, #FFFFFF);
	color: var(--wp--preset--color--primary);
}

.has-secondary-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-secondary: var(--wp--preset--color--secondary, #FFFFFF);
		color: var(--local--color-secondary);
	}
	--yo--color: var(--wp--preset--color--secondary, #FFFFFF);
	color: var(--wp--preset--color--secondary);
}

.has-tertiary-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-tertiary: var(--wp--preset--color--tertiary, #FFFFFF);
		color: var(--local--color-tertiary);
	}
	--yo--color: var(--wp--preset--color--tertiary, #FFFFFF);
	color: var(--wp--preset--color--tertiary);
}

.has-white-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-primary: var(--global--color-white, #FFFFFF);
		color: var(--local--color-primary);
	}
	--my--color: #FFFFFF;
	color: var(--global--color-white);
}



.has-black-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--black, #000000);
		background-color: var(--local--color-background);
	}
	background-color: var(--wp--preset--color--black);
}

.has-white-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--white, #FFFFFF);
		background-color: var(--local--color-background);
	}
	background-color: var(--wp--preset--color--white);
}

.has-primary-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--primary, #FFFFFF);
		background-color: var(--local--color-background);
	}
	// --local-color:#fff;
	--local-color-background: var(--wp--preset--color--primary);
	// background-color: var(--wp--preset--color--primary);
}


.has-secondary-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--secondary, #FFFFFF);
		background-color: var(--local--color-background);
	}
	// --local-color:#fff;
	--local-color-background : var(--wp--preset--color--secondary);
	// background-color: var(--wp--preset--color--secondary);
}

.has-tertiary-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--tertiary, #FFFFFF);
		background-color: var(--local--color-background);
	}
	--local-color:#fff;
	background-color: var(--wp--preset--color--tertiary);
}

.has-grey-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--grey, #fafafa);
		background-color: var(--local--color-background);
	}
	--local-color-background: var(--wp--preset--color--grey);
}

.has-success-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--success, #fafafa);
		background-color: var(--local--color-background);
	}
	--local-color:#fff;
	background-color: var(--wp--preset--color--success);
}
.has-warning-background-color[class] {
	// Localize CSS-variables to limit relationship scope
	> [class*="__inner-container"] {
		--local--color-background: var(--wp--preset--color--warning, #fafafa);
		background-color: var(--local--color-background);
	}
	--local-color:#fff;
	background-color: var(--wp--preset--color--warning);
}


// !! restrict text color when background color is set !!
// .has-background:not(.has-grey-background-color) {
// 	&.has-white-background-color[class] {
// 		color: var(--wp--preset--color--black) !important;
// 	}
// 	&:not(.has-white-background-color) {
// 		color: var(--global--color-white) !important;
// 	}

// }

.has-background:not(.has-text-color) {

	&.has-black-background-color[class],
	&.has-gray-background-color[class],
	&.has-dark-gray-background-color[class] {
	  	color: var(--global--color-white);

		// Localize CSS-variables to limit relationship scope
		> [class*="__inner-container"] {
			--local--color-primary: var(--global--color-background, #FFFFFF);
			// color: var(--local--color-primary, var(--wp--preset--color--primary));
		}
	}

	&.has-white-background-color[class] {
	  	color: var(--wp--preset--color--black);

		// Localize CSS-variables to limit relationship scope
		> [class*="__inner-container"] {
			--local--color-primary: var(--wp--preset--color--primary, #000000);
			// color: var(--local--color-primary, var(--wp--preset--color--primary));
		}
	}
}
 
