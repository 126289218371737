p {
	// letter-spacing: .5px;

	line-height: var(--wp--typography--line-height, var(--global--line-height-body));

	// inherits general font style set at <body>
	&.has-background {
		padding: var(--global--spacing-unit);
	}

	// Override `color: inherit` from Core styles.
	&.has-text-color a {
		color: var(--wp--style--color--link, var(--wp--preset--color--primary));
	}

	strong, b {
		font-weight: 800;
	}
	
	&:not(:last-child) { margin-bottom: .5rem;}

	a {
		color: currentColor !important;
		text-decoration: underline dotted currentColor !important;
	}
}
