.wp-block-columns {
	margin-bottom: 0;
	// border: 1px salmon dotted;
	
	& + .wp-block-columns,
	& + .wp-block-group {
		margin-top: 0 !important;
	}
	/* Start custimizing **/

	& > .wp-block-column.has-background {
		border-radius: var(--yo-radius);
		padding: 2.5rem;
	}

	/* end **/

	&:not(.alignwide):not(.alignfull) {
		clear: both;
	}

	.wp-block-column {

		position: relative;

		> * {
			margin-top: calc(0.66 * var(--global--spacing-vertical));
			margin-bottom: calc(0.66 * var(--global--spacing-vertical));

			@include breakpoint-is(mobile) {
				margin-top: var(--global--spacing-vertical);
				margin-bottom: var(--global--spacing-vertical);
			}

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		.wp-block-group__inner-container {
			padding: 0 2rem;
			@include breakpoint-is(mobile-down) { padding: 0 1rem; };
		}
		
		// reorder for mobile 
		@include breakpoint-is(tablet-down) {
			&.--reorder-1 { order:1 !important; }
			&.--reorder-2 { order:2 !important; }
		}
	}

	.wp-block-column:not(:last-child) {
		// margin-bottom: calc(0.66 * var(--global--spacing-vertical));

		@include breakpoint-is(mobile) {
			// margin-bottom: var(--global--spacing-vertical);
		}

		@include breakpoint-is(desktop) {
			margin-bottom: 0;
		}
	}


	// special for layout with full width block
	&.alignfull {
		gap: 0;
		.wp-block-column {
			&.--stretch { align-self: stretch; }

			&.is-layout-constrained > * {
				// border: 2px solid blue;
				max-width: calc(var(--wp--style--global--content-size) / 1.25);
				@include breakpoint-is(desktop-up) {
					padding: calc( var(--global--spacing-vertical) * 2);
				}
				@include breakpoint-is(laptop-down) {
					padding: calc( var(--global--spacing-vertical) * 1);
				}
				@include breakpoint-is(desktop-down) {
					padding: var(--global--spacing-vertical) calc( var(--global--spacing-vertical) * .5);
				}
		
			}
		}
	}

	// 
	//add pin for HP
	&.--add-pin {
		max-width: 1688px !important;
		position: relative;
		--pin-width: 320px;
		transform: translateY(-6rem);

		&:after {
			// border: 1px dotted red;
			@include pseudo-block(var(--pin-width), calc(var(--pin-width) * 1.5));
			left: calc(50% - var(--pin-width) / 2);
			background-image: url(../medias/pin-3d-blue.png);
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			//
			opacity: 0; transform: scale(.9); transform-origin: bottom center;
			transition: 300ms opacity $smooth 1000ms, 700ms transform $smooth 1000ms;
		}
		@include breakpoint-is(laptop-down) { --pin-width: 160px; }
		@include breakpoint-is(tablet-down) { 
			--pin-width: 120px; 
			&:after { left: unset; right: 1rem; top: calc(var(--pin-width) + 3rem); }
			&:first-child > *:first-child > *:last-child { padding-right: calc(var(--pin-width)/1); }
		}
		.wp-block-column { 
			align-self: flex-start !important;
			padding-top: 0 !important;
		}
	}
	// force to center column content
	&.is-style-center-on-mobile {
		@include breakpoint-is(tablet-down) {
			// border: 2px solid red;
			& > *,
			& > * > * { justify-content: center !important; }
		}
	}
	// 
	&.is-style-compact {
		margin-top: 0;
		margin-bottom: 0;
		gap: 0;
		.wp-block-column {
			& > .wp-block-group {
				margin: 3rem 4rem;
			}
		}
	}

	// fluid table
	&.is-style-fluid {
		gap: 0;
		.wp-block-column {
			flex: unset;
		}
	}

	//hook
	&.--center-on-mobile {
		@include breakpoint-is(tablet-down) {
			.wp-block-column { text-align: center;}
			.wp-block-buttons { align-content: center;}
		}
	}

}
// hook to animate the pin from homepage
.wp-block-cover.--reveal + .wp-block-group .wp-block-columns:after { opacity: unset; transform: unset; }