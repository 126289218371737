.wp-block-cover,
.wp-block-cover-image {
	

	& + * { margin-top: 0 !important;}

	&:not(.alignwide):not(.alignfull) {
		clear: both;
	}

	// height: var(--cover--height);
	// max-height: var(--cover--height);

	min-height: unset;
	padding: 0;
	margin-bottom: calc( var(--global--spacing-horizontal)*2 );

	display: flex;
	// align-items: flex-end;
			
	// Background cover
	.wp-block-cover__image-background {
		width: 100%;  
	}

	// Image
	.wp-block-cover__image-background {
		opacity: 0;
		filter: blur(16px) brightness(3);
		transition: 2000ms filter $smooth, 250ms opacity $smooth;
	}
	
	// Text area
	.wp-block-cover__inner-container {
		width:100%;
		max-width: 1680px;
		margin: 4rem auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 2rem 4rem 2rem 2rem;

		color: #fff;
		
		@include breakpoint-is(laptop-down) { padding: 0 1rem; }
	
		> * { margin: 0 0 1rem 0 !important; }
	}

	&.--reveal {
		.wp-block-cover__image-background { opacity: unset; filter:unset; }
	}

	&.alignleft,
	&.alignright {
		margin-top: 0;

		> * {
			margin-top: calc(2 * var(--global--spacing-vertical));
			margin-bottom: calc(2 * var(--global--spacing-vertical));
			padding-left: var(--global--spacing-horizontal);
			padding-right: var(--global--spacing-horizontal);
			width: 100%;
		}
	}

	/* The background color class is used just for the overlay, and does not need to be applied to the inner container. */
	&[class*="-background-color"][class] .wp-block-cover__inner-container {
		background-color: unset;
	}

	.wp-block-group { 
		margin-bottom: 0;
		& + * { margin-top: 0 !important;}
	}

	figure { margin: 0;}



	// Hook for french flag
	.wp-block-cover__inner-container img[src*='label-made-in-france.png'] { width: 72px !important; }
	@include breakpoint-is(tablet-down) {
		.wp-block-cover__inner-container img[src*='label-made-in-france.png'] { width: 50px !important; }
	}

	// Style is a centered
	&.is-style-centered {
		height: 31rem;
		justify-content: center;
		align-items: center;

		border-radius: var(--yo-radius) 0 var(--yo-radius) 0;

		.wp-block-cover__background { display: none !important; }
		.wp-block-cover__inner-container {
			margin: 0 0 0 0;
			padding: 2rem 2rem; //var(--global--spacing-horizontal);
			max-width: calc(70% - var(--global--spacing-horizontal)*2);
			color: #fff;
			transition: 400ms all $smooth;
			position: relative; z-index: 1;
			
			& > *:last-child { margin-bottom: 0 !important; }

			&:after {
				@include pseudo-block(100%, 100% );
				background: rgba(0,0,0,.6);
				transition: 700ms all $smooth 400ms; z-index: -1;
				transform-origin: top left;
				transform: scaleX(0);
				border-radius: var(--yo-radius) 0 var(--yo-radius) 0;
			}
			// .wp-block-buttons { margin-top: 1rem !important;}
		}
		&.--reveal {
			.wp-block-cover__inner-container {
				&:after,
				&:before { transform: unset;}
			}
		}
		@include breakpoint-is(tablet-down) {
			.wp-block-cover__inner-container { 
				max-width: calc(100% - var(--global--spacing-horizontal)*2); 
				padding: 1rem;
			}
		}
		@include breakpoint-is(desktop-up) { 
			min-height: 512px;
			.wp-block-cover__inner-container { 
				flex-wrap: wrap;
				align-content: center;
			}
		 }
	}
	// Style is a block
	&.is-style-block {

		justify-content: flex-start;

		.wp-block-cover__background { display: none !important; }

		.wp-block-cover__inner-container {
			margin: 0 0 0 calc( 4rem );
			padding: 2.5rem; //var(--global--spacing-horizontal);
			max-width: calc(1688px/2 - var(--global--spacing-horizontal)*2);
			color: var(--wp--preset--color--primary-900);
			transition: 400ms all $smooth;
			position: relative; z-index: 1;
			left: clamp(0px, calc((100vw - 1688px) / 2), calc(1688px/2)); //2024
			
			&:before {
				@include pseudo-block(4rem, 100%, var(--wp--preset--color--primary) );
				left: -4rem; z-index: -1;
				transition: 400ms all ease-in;
				transform-origin: top left;
				transform: scaleX(0);
			}
			&:after {
				@include pseudo-block(100%, 100%, var(--wp--preset--color--secondary) );
				transition: 700ms all $smooth 400ms; z-index: -1;
				transform-origin: top left;
				transform: scaleX(0);
			}
			& > *:last-child { margin-bottom: 0 !important;}
		}
		&.--reveal {
			.wp-block-cover__inner-container {
				&:after,
				&:before { transform: unset;}
			}
		}
		@include breakpoint-is(tablet-down) {
			.wp-block-cover__inner-container { 
				padding: 1.5rem;
				max-width: 100%; 
				margin-left: 2rem;
				&:before { width: 2rem; left: -2rem;}
			}
		}
	}

	// Style is a block with an offset on image
	&.is-style-block-offset {

		justify-content: flex-start;
		align-items: flex-start;
		max-width: 1680px;

		.wp-block-cover__background { display: none !important; }

		.wp-block-cover__image-background {
			width: 75%; height: 80%;
			border-radius: var(--yo-radius);
			left: unset; right: calc( var(--global--spacing-horizontal) * 2);
			top: unset; bottom: calc( var(--global--spacing-horizontal) * 2);
			
		}

		.wp-block-cover__inner-container {
			margin: 0 0 0 calc( 4rem );
			padding: 2.5rem; //var(--global--spacing-horizontal);
			max-width: calc(40% - var(--global--spacing-horizontal)*2);
			color: var(--wp--preset--color--primary-900);
			transition: 400ms all $smooth;
			position: relative; z-index: 1;
			color: #fff;

			&:before {
				@include pseudo-block(4rem, 100%, var(--wp--preset--color--secondary) );
				left: -4rem; z-index: -1;
				transition: 400ms all ease-in;
				transform-origin: top left;
				transform: scaleX(0);
			}
			&:after {
				@include pseudo-block(100%, 100%, var(--wp--preset--color--primary) );
				transition: 700ms all $smooth 400ms; z-index: -1;
				transform-origin: top left;
				transform: scaleX(0);
			}
		}
		&.--reveal {
			.wp-block-cover__inner-container {
				&:after,
				&:before { transform: unset;}
			}
		}

		&.--invert {
			.wp-block-cover__inner-container {
				&:before 	{ background-color: var(--wp--preset--color--primary) ; }
				&:after 	{ background-color: var(--wp--preset--color--secondary) ; }
				color: #000;
			}
		}
		
		@include breakpoint-is(tablet-down) {
			.wp-block-cover__inner-container { 
				padding: 1.5rem;
				max-width: 100%; 
				margin-left: 2rem;
				&:before { width: 2rem; left: -2rem;}
			}
			.wp-block-cover__image-background {
				width: calc(100% - 2rem); right: unset; left: 2rem;
			}
		}

		.cover-caption {
			position: absolute; bottom: 4rem; left: 2rem;
			width: calc(25% - 3rem - var(--global--spacing-horizontal) * 2 );
			display: flex;
			justify-content: flex-end;
			p {
				color: var(--wp--preset--color--grey);
				font-size: .75rem;
				max-width: 200px;
				text-align: right;
			}
		}
	}

	.job_metas {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		li { 
			list-style: none;
			&:not(:last-child):after { content:'-'; padding: 0 .25rem;}
		}
	}

	// &.--flag-made-in-france {
	// 	--flag-width : 168px;
	// 	--flag-padding : 2rem;

	// 	.wp-block-cover__inner-container { padding-right: calc(var(--flag-width) + 1rem); }
		
	// 	&:after {
	// 		min-height: unset;
	// 		border-top-left-radius: 4px;
	// 		background-color: #B5CCFA;
	// 		@include pseudo-block( var(--flag-width), var(--flag-width) );
	// 		left: unset; right:0; top:unset; bottom: 0;
	// 		background-repeat: no-repeat;
	// 		background-position: center;
	// 		content:url('../svg/sprite-made-in-france.svg');
	// 		padding: var(--flag-padding);
	// 	}
	// 	@include breakpoint-is(tablet-down) { 
	// 		--flag-width: 96px;
	// 		--flag-padding: 1rem;
			
	// 	}
	// }

}





// Remove animate from admin
body.wp-admin {
	.wp-block-cover__image-background,
	.wp-block-cover__inner-container {
		opacity: unset !important;
		filter: unset !important;
		&:after,
		&:before { transform:unset !important;}
	}
}


