.wp-block-group {
	// Start IE clearfix.
	// This hack is only necessary because we want to support IE11.
	// If we don't want to support IE11, then "display: flow-root" would suffice.
	display: block;
	clear: both;

	display: flow-root; // stylelint-disable-line declaration-block-no-duplicate-properties

	&:before,
	&:after {
		content: "";
		display: block;
		clear: both;
	}
	// End IE clearfix.

	&.--no-overflow { overflow: hidden;}

	.wp-block-group__inner-container {
		margin-left: auto;
		margin-right: auto;

		> * {
			margin-top: calc(0.666 * var(--global--spacing-vertical));
			margin-bottom: calc(0.666 * var(--global--spacing-vertical));

			&.alignfull {
				@extend %responsive-alignfull-width-mobile;
			}

			@include breakpoint-is(mobile) {
				margin-top: calc(var(--global--spacing-vertical) / 2);
				margin-bottom: calc(var(--global--spacing-vertical) / 2);
			}

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.has-background {
		margin-top		: calc(1 * var(--global--spacing-vertical));
		margin-bottom	: calc(1 * var(--global--spacing-vertical));
		padding			: calc(var(--global--spacing-vertical) * 3) var(--global--spacing-vertical);

		@include breakpoint-is(mobile) {
			padding: var(--global--spacing-vertical) 1rem;
		}
		//
		& > h1, & > h2, & > h3, & > h4, & > h5, & > h6,
		& > p { 
			// padding-left: 0 !important; 
			padding-right: 0 !important; 
		}

		// remove padding when two blocks with background color are one after the other
		&:has( + *.has-background) { margin-bottom: 0 !important; }
		& + *.has-background { margin-top: 0 !important; }
	}

	// Block Styles
	&.is-layout-constrained > *.alignwide {
		max-width: $wide_width;
	}
	
	// Customized
	&.is-style-radius {
		border-radius: var(--yo-radius);
	}
	&.is-style-rounded-white-mask {
		position: relative;
		z-index: 2;
		
		&:after {
			@include pseudo-block(100%, 100%);
			z-index: -1; top:unset; bottom: 0;
			background-image: url('../svg/sprite-rounded-mask.svg');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			transform-origin: bottom center;
			transform: scaleY(.7);
			// border: 1px dotted red;
		}
	}
}
