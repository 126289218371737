.yo-block-bounce {

    margin-top: 2rem;
    
    &.alignleft { 
        float: left;
        margin-left: var(--global--spacing-vertical);
    }
    
    &.alignright { 
        float: right;
        margin-right: var(--global--spacing-vertical);
    }
    
}
