
.yo-block-key-numbers  {
    margin: 0 auto 1rem auto !important;
    padding: 2rem 0 !important;
    border-bottom: 1px solid var(--wp--preset--color--primary);

    &.--reveal {
        .keynumbers__item,
        .keynumbers__number,
        .keynumbers__title,
        .keynumbers__label { opacity: unset !important; transform: unset !important;}
    }

    .keynumbers__list {
        display: flex;
        justify-content: flex-start;
        gap: 2rem;
    }

    .keynumbers__item {
        flex: 1;
        // max-width: 400px;
        margin:0 0rem;
        position: relative;
        padding: 0 1rem;

        // background-color: var(--wp--preset--color--primary-800);
        // border-radius: var(--yo-radius);

        display: flex;
        flex-direction: row;
        align-items: center;

        opacity: 0;
        transition: 300ms all $smooth;
        transition-delay: var(--yo-delay);

        &:not(.has-text-color) .keynumbers__label { color: var(--wp--preset--color--primary-200); }
       
        .keynumbers__number {
            font-size: 0;
            // margin-bottom: .5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // border: 1px dotted black;
            //
            transition: 300ms all $smooth;
            opacity: 0; transform: translateY(-5px);
            transition-delay: var(--yo-delay);

            &.--column { 
                flex-direction: column;
                align-items: center;
                .keynumbers__numb { text-align: center; margin-right: unset;}
                .keynumbers__unit { text-align: center; line-height: .75;}
            }

            .keynumbers__numb {
                position: relative;
                font-size: 4.5rem;
                line-height: 3rem;
                font-weight: 800;
                font-style: normal;
                color: var(--icon-color, var(--wp--preset--color--primary));
                // if not last-child means this number has unit, so snap tio right
                &:not(:last-child) {
                    margin-right: .25rem;
                    text-align: right;
                }
            }
            .keynumbers__unit {
                color: var(--icon-color, var(--wp--preset--color--primary));
                font-size: 1.5rem;
                font-weight: 400;
                margin-top: .5rem;
            }
            .keynumbers__prefix {
                color: var(--icon-color, var(--wp--preset--color--primary));
                font-size: 1.5rem;
                font-weight: 700;
                margin-right:.25em;
                transform: translateY(.5rem);
            }
        }
        
        .keynumbers__title,
        .keynumbers__label {
            transition: 300ms all $smooth var(--yo-delay);
            opacity: 0; transform: translateY(-5px);
        }

        .keynumbers__desc {
            padding-left: .5rem;
        }
        .keynumbers__title {
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: 800;
            // color: var(--icon-color, var(--wp--preset--color--primary));
        } 
        
        .keynumbers__label {
            // padding-top: .5rem;
            font-weight: 300;
            font-size: 1.125rem;
            line-height: 1.5rem;
        } 
        
        .keynumbers__icon {
            width:var(--icon-width, 48px); 
            height: var(--icon-width, 48px);
            margin-bottom: 0rem;
            img {
                @include object-fit(contain);
                object-position: bottom;
            }
        } 
        
    }

    //
    @include breakpoint-is (desktop-down) {
        margin: 0 !important;
        padding: 1rem 0 !important;
        .keynumbers__list {
            flex-direction: column;
            gap: 1rem;
        }
        .keynumbers__item   { padding-left: 0; }
        .keynumbers__numb   { font-size: 3.5rem !important;}
        .keynumbers__prefix { font-size: 1rem !important; line-height: 1;}
        .keynumbers__desc   { padding-left: 0rem !important; }
    }
    @include breakpoint-is (mobile) {
        // .keynumbers__item { flex: 0 0 calc(100% - 2rem); }
    }
} 