.yo-block-tabs {
    background-color: unset !important;
    margin-bottom: calc(var(--global--spacing-horizontal) * 1);

    display: flex;
    flex-direction: column;
    border-radius: var(--yo-radius);

    .tabs__labels {
        margin: 0 auto; 
        padding: 0;
        position: relative;
        &:before {
            @include pseudo-block(100%, 2px, var(--wp--preset--color--grey) );
            top:unset; bottom: 0;
        }

        // manage custom colors
        &.has-background {
            &:before, .tab__li:before { background-color: #fff !important;}
        }
        
        .tab__li {
            flex: 1 1 0;
            display: inline-block;
            list-style: none;
            position: relative;
            padding: 1rem 2rem;
            position: relative;
            user-select: none;
            & > * { pointer-events: none;}
            &:before {
                @include pseudo-block(100%, 4px, var(--wp--preset--color--grey));
                top:unset; bottom: 0;
                transition: 600ms all $smooth;
                transform: scaleY(0); transform-origin: bottom left;
            }

            &[aria-selected="true"] {
                cursor: default;
                font-weight: bold;
                &:before { 
                    transform: unset;
                    background-color: var(--wp--preset--color--secondary);
                }
            }
            
            &:not([aria-selected="true"]):hover { 
                cursor: pointer;
                &:before { transform: unset; }
            }
        }
    }
    
    .tab__text.--responsive { display: none;}

        .tab__content {
            margin-top: 0;
            padding: 1rem 2rem 1rem 2rem;
            width: 100%;
            display: none; 
            
            &[aria-expanded="true"]{ 
                display: flex;
                .tab__text  { animation: 300ms reveal_text $smooth; }
            }
        }

        .tab__text {
            height:100%; 
            margin: 0 auto;
        }

        @keyframes reveal_text {
        0%    { opacity: 0; transform: translateY(5px);}
        30%   { opacity: 0; transform: translateY(5px);}
        100%  { opacity: 1; transform: translateY(0px); }
        }

    //
    //unset to be able to support colors changes from admin
    .wp-block-group__inner-container {
        background-color: unset !important;
    }


    @include breakpoint-is(mobile) {
        .tab__li,
        .tab__content { padding:1rem !important; }
    }

}