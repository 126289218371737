.wp-block-media-text {
	
	margin-top: 0;
	margin-bottom: 0;

	&.alignfull {
		margin-top: 0;
		margin-bottom: 0;
	}

	a:focus img {
		outline-offset: -1px;
	}

	.wp-block-media-text__content {
		padding: 1rem 0rem;

		> * {
			margin-top: calc(0.666 * var(--global--spacing-vertical));
			margin-bottom: calc(0.666 * var(--global--spacing-vertical));

			@include breakpoint-is(mobile) {
				margin-top: var(--global--spacing-vertical);
				margin-bottom: var(--global--spacing-vertical);
			}

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	/**
	 * Block Options
	 */

	&.is-stacked-on-mobile .wp-block-media-text__content {
		@include breakpoint-is(mobile) {
			padding-top: var(--global--spacing-vertical);
			padding-bottom: var(--global--spacing-vertical);
		}
	}

	// 
	.wp-block-media-text__media {
		padding: 0;
		position: relative;
		overflow: hidden;
	}



	/**
	 * Alignements
	 */
	&.has-media-on-the-right {
		.wp-block-media-text__media {
			border-radius: var(--yo-radius) 0 0 0;
		}
		.wp-block-media-text__content { 
			padding-right: 4rem;
			@include breakpoint-is(laptop-down) { padding-right: 1rem;}
		}
		&.alignfull .wp-block-media-text__content { 
			padding-left: var(--global--spacing-vertical);
			text-align: right;
		}
	}
	&:not(.has-media-on-the-right) {
		.wp-block-media-text__media {
			border-radius: 0 0 var(--yo-radius) 0;
		} 
		.wp-block-media-text__content {
			padding-left: 4rem;
			@include breakpoint-is(desktop-down) { padding-left: 0rem; }
		}
	}

	&.alignfull {
		.wp-block-media-text__content {
			justify-self: flex-end;
			max-width: calc( 1480px / 2);
		}
		.wp-block-media-text__media {
			max-width: 100% !important;
			max-height: 100vh !important;
		}
	}

	/**
	 * Custom Options
	 */
	&.is-style-banner {
		background-color: unset !important;
		
		.wp-block-media-text__media {
			border-radius: var(--yo-radius) 0 var(--yo-radius) 0;
			z-index: 22;
			max-height: 80vh;
			img {
				object-fit: contain !important;
				// object-fit: contain !important;
				// object-position: center !important;
			}
		} 
		&.has-background {
			&.has-primary-background-color 		{ --banner-color: var(--wp--preset--color--primary);}
			&.has-secondary-background-color 	{ --banner-color: var(--wp--preset--color--secondary);}
			.wp-block-media-text__content {
				position: relative;
				padding: calc( var(--global--spacing-vertical) * 2);
				background-color: var(--banner-color);
				&:before { 
					@include pseudo-block(250%, 100%, var(--banner-color));
					transform-origin: top left;
					transform: scaleX(-1);
				}
				&:after { 
					@include pseudo-block(250%, 100%, var(--banner-color));
					left: 100%;
					transform-origin: top right;
				}
			}
		}
		
	}


	//
	// disable click on backoffice
	&.--context-admin {
		a { pointer-events: none; cursor: default; ;}
	}

	/*
	&.has-media-on-the-right img.
		&[src$=".jpg"], &[src$=".jpeg"] {
			border-radius: 10px;
			filter: drop-shadow(0px 30px 50px rgba(27, 6, 51, 0.3));
		}
	*/
	
}
