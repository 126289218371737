/**
 * Button
 */
 .site .button,
 .wp-block-search .wp-block-search__button,
 .wp-block-button .wp-block-button__link,
 .wp-block-file a.wp-block-file__button {
	 // Extend button style
	 &:not([id*=acf]):not([id*=mceu]):not([id*=slick]):not([class*=spectra]):not([class*=components-button]):not([class*=customize]):not(.button--toggle-menu) {
		@include button-style();
	 }
 }
 
 /**
  * Block Options
  */
 .wp-block-buttons {
	 gap: .5rem 1.5rem;
 }
 .wp-block-button {
 
	 // Target the outline button states.
	 &.is-style-outline {
		.wp-block-button__link {
			background: unset !important;
			--my-color : var(--wp--preset--color--secondary);
			border-color: var(--my-color) !important;
			color: var(--my-color) !important;
			line-height: 1;

			&.has-primary-color		{ --my-color: var(--wp--preset--color--primary); }
			&.has-secondary-color	{ --my-color: var(--wp--preset--color--secondary); }
			&.has-black-color		{ --my-color: var(--wp--preset--color--black); }
			&.has-white-color 		{ --my-color: #fff; }

			&:hover {
				background-color: var(--my-color) !important;
				color: var(--wp--preset--color--white) !important;
				&.has-white-color { color: var(--wp--preset--color--primary) !important}
			}
		}
		//  .wp-block-button__link:hover {
		// 	 &.has-success-background-color 		{ --my-color: var(--wp--preset--color--success-700) !important; }
		// 	 &.has-danger-background-color 		{ --my-color: var(--wp--preset--color--danger-700) !important; }
		// 	 &.has-warning-background-color 		{ --my-color: var(--wp--preset--color--warning-700) !important; }
		// 	 &.has-info-background-color 		{ --my-color: var(--wp--preset--color--info-700) !important; }
		//  }
	 }
 
	 // Target the default and filled button states.
	 &:not(.is-style-outline) {
 
		.wp-block-button__link:not(:hover):not(:active) {
			&.has-primary-background-color,
			&:not(.has-background) {
				background: var(--wp--preset--color--primary);
			}
		}
		 
		&:hover .wp-block-button__link {
			 background: var(--wp--preset--color--secondary); 
			 &.has-secondary-background-color {
			 	background: var(--wp--preset--color--primary) !important; 
			 }
		}
		 
		// Hover Button color should match parent element foreground color	
		.wp-block-button__link {
			outline: 10px solid transparent;
			outline-offset: -3px;	
			padding: .5rem 1rem;
			line-height: 1;
			text-transform: uppercase;
		}
		// Force black text on secondary background color
		.wp-block-button__link {
			&.has-white-background-color { color: #000 !important; }
			background-color: unset;
		}
		//
		&:not([class*=is-style-illus]):not([class*=is-style-icon]):hover .wp-block-button__link {
			box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, .5);
		}
	 }
 
	 //
	 // Target button with icon
	 &[class*=is-style-icon] { //:not(.is-style-outline) {
		 position: relative;
		 text-transform: uppercase;
		 .wp-element-button { 
			 padding-right: 3rem !important; 
		 }
		 .wp-block-button__link {
			 &:not([class*=background-color]){
				//  background-color: unset !important;
				//  color: inherit;// var(--wp--preset--color--black) !important;
				 &:before { filter: invert(0); }
			 }
			 &[class*=background-color]{
				 &:before { filter: brightness(0) invert(1); }
			 }
			 &:not([class*=text-color]){
				 &:before { filter: brightness(0) invert(0); }
			 }
			 &:before { 
				 @include pseudo-block(2rem, 2rem);
				 left: unset; right: .5rem; top:.4rem;
				 background-repeat: no-repeat;
				 background-position: center;
				 pointer-events: none;
				 transition: 400ms all $smooth;
			 }
		 }
		 &:hover .wp-block-button__link {
			//  color: var(--wp--preset--color--primary) !important;
			 &:before { 
				//  filter: invert(41%) sepia(33%) saturate(6575%) hue-rotate(339deg) brightness(94%) contrast(103%);
				transform: translateX(3px);
			 }
		 }
 			 
		 &.is-style-icon-arrow 		.wp-block-button__link:before			{ content:url('../svg/icon-arrow-right.svg'); }	
		 &.is-style-icon-external 	.wp-block-button__link:before			{ content:url('../svg/icon-external.svg'); }	
		 &.is-style-icon-download 	.wp-block-button__link:before			{ content:url('../svg/icon-download.svg'); }	
		 &.is-style-icon-pin 		.wp-block-button__link:before			{ content:url('../svg/icon-pin.svg'); }	
		 
		 &.is-style-icon-write .wp-block-button__link{
			padding-left: 3rem !important;
			padding-right: 1.5rem !important;
		 	&:before { 
				left: 1rem; right: unset;
				content:url('../svg/icon-write.svg'); 
				filter: invert(0);
			}	
		 }
 
 
		 // is size L or XL
		 &.has-large-font-size,
		 &.has-x-large-font-size {
			 .wp-element-button { 
				 padding-right: 5rem !important; 
			 }
			 .wp-block-button__link{
				 padding-right: calc(var(--icon-size) * 2) !important;
				 border-radius: 2.5rem !important;
				 height: 5rem !important;
				 &:before { 
					 width:var(--icon-size); 
					 height: var(--icon-size); right:22px; 
				 }
			 }
		 }
		 &.has-large-font-size {
			 --icon-size:32px;
			 .wp-block-button__link {
				 &:before { top:22px; }
			 }
		 }
		 &.has-x-large-font-size {
			 --icon-size:48px;
			 .wp-block-button__link {
				 &:before { top:20px; }
			 }
		 }
 
	 }
	 
	 //
	 //
	 // Change size
	 // &.has-custom-font-size {
		 // .wp-block-button__link { 
		 // 	height: var(--height) !important;
		 // 	border-radius: calc(var(--height) / 2) !important;
			 
		 // }
		 // &.has-small-font-size .wp-block-button__link { 
		 // 	font-size: var(--global--font-size-xs) !important; 
		 // 	padding: 0 .75rem !important;
		 // 	--height: 24px;
		 // }
		 // &.has-medium-font-size .wp-block-button__link { 
		 // 	font-size: var(--global--font-size-sm) !important;
		 // 	--height: 30px;
		 // }
		 // &.has-large-font-size .wp-block-button__link { 
		 // 	font-size: var(--global--font-size-lg) !important; 
		 // 	padding: 0 1.75rem !important;
		 // 	--height: 48px;
		 // }
		 // &.has-x-large-font-size .wp-block-button__link { 
		 // 	font-size: var(--global--font-size-xl) !important; 
		 // 	--height: 72px;
		 // 	padding: 0 2rem !important;
		 // }
	 // }
 
	 &.--disabled {
		 pointer-events: none;
		 filter: saturate(0);
	 }
 }
 
  