/* Transition */
$bezier         	: cubic-bezier(0.68, -0.55, 0.265, 1.15);  
$bounce         	: cubic-bezier(0.03, 1.12, 0.65, 0.9);  
$smooth         	: cubic-bezier(0.18, 1, 0.22, 1);  
$max_width			: 1680px;

/* Vertical Rhythm Multiplier */
$baseline-unit		: 16px;

:root {
	
	/* Font Family */
	--font-headings : 'Raleway', Arial, sans-serif;
	--font-base 	: 'Raleway', Arial, sans-serif;
	--font-bold 	: 'Raleway', Arial, sans-serif;

	/* Font Size */
	--global--font-size-base	: 16px; 
	
	--global--font-size-xs		: calc( .8 * var(--global--font-size-base));	// px
	--global--font-size-sm		: calc( 1 * var(--global--font-size-base)); 	// px
	--global--font-size-md		: calc( 1.25 * var(--global--font-size-base)); 	// px
	--global--font-size-lg		: calc( 2 * var(--global--font-size-base)); 	// px
	--global--font-size-xl		: calc( 3 * var(--global--font-size-base)); 	// px
	--global--font-size-xxl		: calc( 3.5 * var(--global--font-size-base)); 	// px
	// --global--font-size-xxxl	: 5rem; // 80px / 16px
	--global--letter-spacing	: normal;

	/* Line Height */
	--global--line-height-body: 1.5;
	--global--line-height-heading: 1;

	/* Colors */
	--color-heading: #004B7A;
	--color-text: #000; 

	/* Headings */
	--heading--font-family		: var(--font-base);

	--heading--font-size-h6		: var(--global--font-size-xs);
	--heading--font-size-h5		: var(--global--font-size-sm);
	--heading--font-size-h4		: var(--global--font-size-md);
	--heading--font-size-h3		: var(--global--font-size-lg);
	--heading--font-size-h2		: var(--global--font-size-xl);
	--heading--font-size-h1		: var(--global--font-size-xxl);

	--heading--line-height-h6	: var(--global--line-height-heading);
	--heading--line-height-h5	: var(--global--line-height-heading);
	--heading--line-height-h4	: var(--global--line-height-heading);
	--heading--line-height-h3	: var(--global--line-height-heading);
	--heading--line-height-h2	: var(--global--line-height-heading);
	--heading--line-height-h1	: var(--global--line-height-heading);

	/* Colors */
	// add some primary & secondary teint
	
	--wp--preset--color--success-700 	: #007f36;
	--wp--preset--color--danger-700 	: #C70C0C;
	--wp--preset--color--warning-700 	: #AC5D00;
	--wp--preset--color--info-700 		: #009494;

	--global--color-white-50	: rgba(255, 255, 255, 0.5); // Used for disabled buttons
	--global--color-white-90	: rgba(255, 255, 255, 0.9); // Used in form fields.

	--global--color-background	: #fff; /* Default body background */
	--global--color-border		: var(--wp--preset--color--primary); /* Used for borders (separators) */

	/* Spacing */
	--global--spacing-measure	: unset; // Use ch units here. ie: 60ch = 60 character max-width
	--global--spacing-unit		: #{2 * $baseline-unit}; // 32px
	--global--spacing-horizontal: #{2 * $baseline-unit}; // 32px
	--global--spacing-vertical	: #{2 * $baseline-unit}; // 32px.

	/* Buttons */
	// Fonts
	--button--font-family: var(--font-bold);
	--button--font-size: 1rem;
	--button--font-weight: normal;
	// Spacing
	--button--padding-vertical: .5rem;
	--button--padding-horizontal: 1rem; 

	/* Navigation height */
	--menu-height: 172px;
	--search-height: 66px;

	/* Cover block */
	--cover--height: 720px; //calc(20 * var(--global--spacing-vertical));
	--cover--color-foreground: transparent;
	--cover--color-background: transparent;
	
	/* Radius values */
	--yo-radius	: 2rem;

	/* Admin-bar height */
	--global--admin-bar--height: 0px;
}

.admin-bar {
	--global--admin-bar--height: 32px;

	@media only screen and (max-width: 782px) {
		--global--admin-bar--height: 46px;
	}
}

// @media only screen and (min-width: 652px) { // Not using the mixin because it's compiled after this file
// 	:root {
// 		--global--font-size-xl: 2.5rem; // 40px / 16px
// 		--global--font-size-xxl: 6rem; // 96px / 16px
// 		--global--font-size-xxxl: 9rem; // 144px / 16px
// 		--heading--font-size-h3: 2rem; // 32px / 16px
// 		--heading--font-size-h2: 3rem; // 48px / 16px
// 	}
// }
