/* STREAM  *****/
.ff-stream {
    background-color: unset !important;
    min-height: unset !important;
    padding: 0 0 2rem 0;
}
.ff-loadmore-wrapper {
    display: none;
}
.ff-item img { transform: unset !important;}

                // /* DOWNLOAD LIST  *****/
                // .yo-block-download-list {
                //     .download-list { 
                //         width: calc(90% - 1rem);
                //         li { flex: 0 0 100% !important;}
                //     }
                // }

/* Obfuscate *****/

.wp-block-social-link span {
    padding: .25em;

    border-bottom: 0;
    box-shadow: none;
    text-decoration: none;

    align-items: center;
    display: flex;
    line-height: 0;
    transition: transform .1s ease;    
    font-size: unset !important;
    margin: unset !important;
}

/* POST  *****/
.single-post .entry-content {  
    display: flex; flex-direction: column;
    & > * { order:4; }
    
    .wp-block-breadcrumbs { order: 1;} 
    .wp-block-cover { order: 2;} 
    
    .wp-block-post-metas {
        margin: 0 0 2rem 0;
        order:3;
        font-size: .8rem;
        color: var(--wp--preset--color--primary-900);
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        // border: 1px dotted red;
        // & > * { border: 1px dotted red; }

        .post--categories {
            display: flex;
            padding-left: .5rem;
            li { 
                list-style: none;
                background-color: var(--wp--preset--color--primary-900);
                padding: .5rem 1rem;
                border-radius: 1rem;
                &:not(:last-child) { margin-right: .5rem;}
                a { color: #fff; text-decoration: none !important;}
            } 
        }
        .post--date { 
            color: var(--wp--preset--color--black);
        }
        .yo-block-sharing {
            display: flex;
            align-items: center;
            margin: 0;
            color: var(--wp--preset--color--black);
            li { 
                list-style: none;
                padding: 0;
                cursor: pointer;
                &:not(:first-child) { margin: 0 .5rem;}
                &:hover svg { fill: var(--wp--preset--color--primary);}
                svg {
                    height: 1rem;
                    pointer-events: none;
                }
            }
        }
        .post--back-list {
            color: var(--wp--preset--color--secondary);
        }
    }
}


/* CLASSiC  *****/
body.--no-overflow { overflow: hidden; }



/*  *****/
.yo-block-jobs {
    margin-top: var(--global--spacing-vertical) !important;
    .icon.--filter {
        fill: var(--wp--preset--color--primary);
        width: 1rem;
    }
}

.job__description {}
.job__link_back {
    color: var(--wp--preset--color--black);
    svg {
        width: 16px; height: 26px;
        transform: rotate(180deg);
        fill: var(--wp--preset--color--primary);
    }
}

/* Contacts list *****/
.contacts_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
    .list_contact {
        flex: 0 0 300px;
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        .contact_label {
            font-weight: 700;
            color: var(--wp--preset--color--black);
            font-size: 1.25rem;
            display: flex;
            align-items: center;
            position: relative;
            img {
                height: 52px;
                margin-right: 3rem;
            }
            &:before {
                @include pseudo-block(2rem, 1px, var(--wp--preset--color--primary));
                top: 50%; left: calc(52px + 4px);
            }
        }
        .contact_phone {
            margin: .5rem 1rem 1rem 1rem;
            height: 40px;
            border-radius: 20px;
            background-color: var(--wp--preset--color--secondary);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
            font-weight: 700;
            svg { fill: #fff; height: 16px; margin-right: 1rem;}
            text-decoration: unset !important;
            &:hover {
                background-color: var(--wp--preset--color--grey-100);
                color: var(--wp--preset--color--secondary);
                svg { fill: var(--wp--preset--color--secondary);}
            }
        }
        .contact_address {
            margin: 0 1rem;
            padding: 0 1.5rem;
        }
        .contact_link {
            margin: .5rem 1rem 1rem 1rem;
            border-radius: 4px;
            background-color: var(--wp--preset--color--grey-100);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 2rem;
            font-weight: 700;
            svg { fill: var(--wp--preset--color--secondary); height: 16px; margin-right: 1rem;}
            text-decoration: unset !important;
            &:hover {
                background-color: var(--wp--preset--color--secondary);
                color: #fff;
                svg { fill: #fff;}
            }
        }
    }
}

/* HOOK for margin & padding *****/

.--no-margin            { margin: 0 !important;
    & > .wp-block-group__inner-container {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}
.--no-margin-top        { margin-top: 0 !important;}
.--no-margin-bottom     { margin-bottom: 0 !important;}
.--no-padding           { padding: 0 !important;}
.--no-padding-top       { padding-top: 0 !important;}
.--no-padding-bottom    { padding-bottom: 0 !important;}
.--overflow             { overflow: hidden !important;}

