.wp-block-breadcrumbs {
    // border: 1px dotted red;
    max-width: $max_content_width !important;
    width:100%;
    margin: .5rem auto 0 auto !important;
    
    & + * { margin-top: .5rem !important;}
    
    .breadcrumbs {
        // border: 1px dotted green;
        line-height: 1;
        display: flex;

        font-size: .75rem;
        font-weight: 400;
        color: var(--wp--preset--color--grey-500);
        padding-left: 0;
        @include breakpoint-is(desktop-up) { padding-left: 1.2rem; }

        li { 
            list-style: none;
            &, a { color : inherit;}
            a { text-decoration: none;}
            
            &.separator { 
                margin: .35rem .5rem 0 .5rem;
                position: relative;
                color: var(--wp--preset--color--primary);
                font-size: 1.5rem;
                line-height: 0;
            }
            &:last-child,
            &.item-current {
                color: var(--wp--preset--color--black);
                font-weight: 700;
                pointer-events: none;
            }
            
            
        }
        // *[aria-current="page"] { opacity: .5; }
    
        // @include breakpoint-is(desktop-down) {
        //     font-size: .8rem;
        //     margin-bottom: 0 !important;
        //     li {
        //         &:last-child { display: none;}
        //     }
        // }
    
    }
}

//Hook when menu is transparent
// .entry-content.--is-transparent-menu .wp-block-breacrumbs {
//     position: absolute !important;
//     & + * { margin-top: 0 !important;}
// }