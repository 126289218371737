// 
.yo-block-gallery,
.wp-block-gallery {

	--gallery-block--gutter-size : 4rem;

	// &:not(.columns-1) .flickity-viewport { overflow: unset;}
	&.columns-1 { --width: 100%; }
	&.columns-2 { --width: 50%; }
	&.columns-3 { --width: 33%; }
	&.columns-4 { --width: 25%; }
	&.columns-default,
	&.columns-5 { --width: 20%; }
	&.columns-6 { --width: 16.5%; }
	&.columns-7 { --width: 14%; }
	&.columns-8 { --width: 12.5%; }

	@include breakpoint-is(desktop-down) {
		--width: 50% !important;
	}
	
	.wp-block-image {
		margin: 0 !important;
		max-width: calc(var(--width, 20%) - 0rem) !important; 
		img { 
			// border-radius: var(--yo-radius); 
			overflow: hidden;
		}
	}

	// Caption
	.wp-element-caption {
		position: absolute; z-index: 33;
		bottom: .5rem; left:0; right: 0;
		padding: .5rem 1rem !important;
		width: fit-content !important;
		font-size: .85rem !important;
		line-height: 1 !important;
		margin: 0 auto !important;
		background: #fff !important;
		color: var(--wp--preset--color--black) !important;
		border-radius: calc(var(--yo-radius) / 2);
	}

	// Link
	a { 
		width: 100%; height: 100%;
		cursor: pointer;
	}

	&.is-style-icon {
		justify-content: center;
		gap: 2rem !important;
		margin-bottom: 0 !important;
		.wp-block-image {
			max-width: clamp(4rem, var(--width), 248px) !important;
			img { 
				width: 128px !important; 
				object-fit: contain !important;
				flex: 1 0 calc(90% - 2rem) !important;
				margin: 0 auto .75rem auto;
				padding-bottom: 4rem;
			}
			&:has(a):hover {
				a {
					border-width: 0 1px 1px 0;
					border-radius: 1rem;
					box-shadow: 0 5px 10px rgba(0,0,0,.1);
				}
				&:before { opacity: .7;}
			}
			&:has(a) {
				&:before {
					text-align: center;
					font-size: .8rem;
					font-weight: 700;
					color: var(--wp--preset--color--grey);
					position: absolute;
					left: 0; right: 0; margin: 0 auto;
					bottom: 3.25rem;
					opacity: 0;
					pointer-events: none;
				}
			}
			&:has(a[href$='.pdf']):before 			{ content:'Téléchargez le PDF'; }
			&:has(a:not([href$='.pdf'])):before 	{ content:'Voir le site'; }
			
		}
		.wp-element-caption {
			pointer-events: none;
			font-weight: 700;
			bottom: unset !important;
			background: unset !important;
			border-radius: unset !important;
			font-size: 1.1rem !important;
			line-height: 1.2 !important;
			max-height: unset !important;
			overflow: unset !important;
			position: relative !important;
			bottom: 4rem !important;
			white-space: pre;
		}
		// border: 1px solid red;
		// figure { border: 1px dotted pink; }
	}
	

}

.yo-block-gallery {
	margin-bottom: 4rem;
	&.is-cropped {
		.wp-block-image img { @include object-fit(cover); }
	}

	
	// Slider with image
	&.is-style-slider {
		min-height: 320px;
		// border: 1px dotted orangered;
		
		&.has-nested-images .wp-block-image { 
			padding: 0;
			img { @include object-fit(contain); }
		}
		
		&.columns-1 .wp-block-image {margin: 0 !important;}

		.wp-block-image {
			margin: 0 1rem !important;
			display: flex;
			align-items: center;
			justify-content: center;
			
			img { 
				filter: grayscale(1);
				opacity: .4;
				transition: 400ms opacity $bounce;
			}
			&.is-selected {
				img { 
					filter: unset;
					opacity: unset;
				}
				.wp-element-caption { transform:unset; opacity: unset; }
			}
		}
	}

	// Slider
 	.flickity-viewport { 
		width: 100%;
		position: relative;
		z-index: 7;
	}
	.flickity-slider { 
		display: flex;
		align-items: center;
	}
		
	.flickity-button {
		position:absolute; bottom:.5rem; z-index: 121;
		&.previous 	{ left: .5rem;}
		&.next 		{ left: calc(3rem - 2px);}
	}

	// Caption
	.wp-element-caption {
		transition:600ms all $smooth 600ms;
		opacity: 0;
	}
	
	
}


// .wp-block-gallery {

// 	margin: calc(2 * var(--global--spacing-vertical)) auto;

// 	--wp--style--gallery-gap-default: 3rem;

// 	.wp-block-image {
// 		margin: unset !important;
// 		a {
// 			text-align: center;
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 		}
// 	}

// 	&.is-style-fluid {
// 		// flex-wrap: nowrap;
// 		justify-content: flex-start !important;
// 		align-items: flex-start !important;
// 		gap: unset !important;	
		
// 		.wp-block-image {
// 			width: unset !important;
// 			flex: unset !important;
// 			// border:1px dotted red;
// 			&:not(:last-child) { margin-right: 1rem !important;}
// 			img {
// 				// border: 1px solid red;
// 				max-height: 80px !important;
// 				object-fit: contain;
// 			}
// 		}
// 	}
// }
 