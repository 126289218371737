.search-no-results .page-content {
	margin-top: calc(3 * var(--global--spacing-vertical));
}

.highlight-search-term {
	color:var(--wp--preset--color--tertiary);
}


.search-wrapper {
	form {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		position: relative;
		padding: .5rem;
		// box-shadow: 0px 0px 4px var(--wp--preset--color--primary), 0px 0px 16px rgba(255, 255, 255, 0.2);
		border: 1px solid var(--wp--preset--color--primary);
		border-radius: 25px;
		label { display: none;}
		::placeholder { font-style: italic;}
	}
	input[type=text] {
		border: none;
		font-size: 1rem;
		color: var(--wp--preset--color--primary);
		padding: .5rem;
		background: unset;
		flex: 0 1 100%;
		&:focus { 
			color: var(--wp--preset--color--primary);
			outline: none;
			border-color: var(--wp--preset--color--primary);
		}
	}
	a {
		padding: 0 .5rem !important;
		&.button--search-submit {
			color: #000;
			font-weight: 700;
			text-transform: uppercase;
			border-radius: 50%;
			transition: 400ms all $smooth;
			&:hover {
				background-color: var(--wp--preset--color--secondary);
			}
			&.--disable {
				pointer-events: none;
				opacity: .6;
				filter: saturate(0);
			}
		}
		&.button--search-close {
			padding: 0 !important;
			margin-left: .5rem;
			svg { fill:var(--wp--preset--color--primary)}
			&:hover svg { fill:var(--wp--preset--color--grey-700) !important; }
		}
	}
	
	@include breakpoint-is(desktop-down) {
		// border: 1px solid red;
		width: 100%;
		padding: 0 1rem;
		// left:0; margin:0 auto;
		// width: 100%; height: 200px;
		// form {
		// 	flex-direction: column;
		// 	justify-content: center;
		// }
		// input, a { 
		// 	font-size: 1.5rem !important;
		// 	margin-bottom: 1rem !important;
		// }
	}
}
