// Do we need to serve a font? Add the @font-face styles here.
@font-face {
    font-family: 'Marianne-Regular';
    src: url('../medias/fonts/Marianne-Regular.woff2') format('woff2'),
         url('../medias/fonts/Marianne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne-Light';
    src: url('../medias/fonts/Marianne-Light.woff2') format('woff2'),
         url('../medias/fonts/Marianne-Light.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne-Bold';
    src: url('../medias/fonts/Marianne-Bold.woff2') format('woff2'),
         url('../medias/fonts/Marianne-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

