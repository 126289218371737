// table,
.wp-block-table {
	width: 100%;
	min-width: 240px;
	border-collapse: collapse;

	thead,
	tfoot {
		text-align: center;
	}

	thead 	{ 
		border-bottom: 3px solid var(--wp--preset--color--primary); 
		tr, td { border: unset !important;}
	}
	tfoot 	{ border-top: 3px solid var(--wp--preset--color--primary); }

	th {
		font-family: var(--heading--font-family);
	}

	td,
	th {
		padding: calc(0.5 * var(--global--spacing-unit));
		border: none;
		border-top: 1px solid var(--wp--preset--color--primary);
	}

	figcaption {
		color: var(--wp--preset--color--primary);
		font-size: var(--global--font-size-xs);
	}

	&.is-style-regular .has-background,
	&.is-style-stripes .has-background,
	&.is-style-stripes .has-background thead tr,
	&.is-style-stripes .has-background tfoot tr,
	&.is-style-stripes .has-background tbody tr {
		color: var(--wp--preset--color--grey-700);
	}

	&.is-style-stripes {
		border-color: var(--wp--preset--color--grey-100);

		th,
		td {
			border-width: 0;
		}

		tbody tr:nth-child(odd) {
			background-color: var(--wp--preset--color--primary-light) !important;
		}

		tbody tr:nth-child(even) {
			background-color: #fff !important;
		}

	}
}

table.wp-calendar-table {

	td,
	th {
		background: transparent;
		border: 0;
		text-align: center;
		line-height: 2;
		vertical-align: middle;
		word-break: normal;
	}

	th {
		font-weight: bold;
	}

	thead,
	tbody {
		color: currentColor;
		border: 1px solid;
	}

	caption {
		font-weight: bold;
		text-align: left;
		margin-bottom: var(--global--spacing-unit);
		color: currentColor;
	}
}

.wp-calendar-nav {
	text-align: left;
	margin-top: calc(var(--global--spacing-unit) / 2);

	svg {
		height: 1em;
		vertical-align: middle;

		path {
			fill: currentColor;
		}
	}

	.wp-calendar-nav-next {
		float: right;
	}
}
