.card {
    display: flex;
    flex: 1 1 100%;
    position: relative;
    // reset card
    margin: 0;
    padding: 0;
    border: unset;
    box-shadow: unset;
    text-decoration: none !important;
    
    
    // animate
    opacity: 0; 
    transition: 500ms opacity $smooth, 1000ms transform $smooth;
    &:hover .card__inner {
        // box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
        filter: drop-shadow(0 0 2rem rgba(0, 0, 0, 0.1));
    }
    .card__inner {
        width: 100%;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        transition: 500ms all $smooth;
        border-radius: var(--yo-radius) 0 var(--yo-radius) 0;
        border-bottom: 1px solid var(--wp--preset--color--grey-50);
        background-color: #fff;
        text-decoration: none !important;
        user-select: none;
    }

    .card__thumb {
        max-height: 360px;
        flex: 1 0 50%; 
        img { 
            @include object-fit(cover); 
            border-radius: var(--yo-radius) 0 0 0;
        }
    }
    
    .card__infos {
        position: relative;
        padding: 2rem 2rem  2rem  1rem;
        display: flex;
        flex-direction: column;
        flex: 1 0 50%; 
        transition: 500ms all $smooth;
        color: var(--wp--preset--color--black);
    }

    .card__title {
        &:has(+ .card__excerpt) { margin-bottom: .5rem !important;}
        color: var(--wp--preset--color--black);
        font-weight: 900;
        line-height: 1.1;
    }
    .card__excerpt {
        font-size: 1rem;
    }
    .card__more {
        flex: 1 0 auto;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .card__date {
        font-size: .7rem;        
    }
    .card__read { 
        font-size: .75rem;
        transform: translateY(.5rem);
    }

    .card__metas { 
        margin: 0 0 .5rem 0;
        padding: 0;
        display: flex;
        li {
            &:not(:last-child) { margin-right: .5rem;}
            padding: .25rem .5rem;
            list-style: none;
            font-size: .7rem;
            color:#fff;
            background-color: var(--wp--preset--color--primary);
            border-radius: 1rem;
        }
        // .card__categories   { background-color: var(--wp--preset--color--primary);}
        // .card__tags         { background-color: var(--wp--preset--color--grey-700);}
    }

   

    
    /* set sticky card */
   &.--is-sticky {
    margin-bottom: 2rem;
    &::before {
        @include pseudo-block(100vw, calc(100% - 4rem), var(--wp--preset--color--secondary));
        top: 2rem;
        left: calc( (100vw - 1240px) / 2 * -1);
    }
    .card__inner {
        background-color: unset;
        border: unset;
        .card__thumb {
            order: 2;
            img { border-radius: 0 var(--yo-radius) 0 var(--yo-radius); }
        }
        .card__infos {
            order: 1;
            .card__metas{
                margin-top: 2rem;
                justify-content: flex-end;
            }
            .card__title, .card__excerpt {
                text-align: right;
                color: #fff;
            }
            .card__title { font-size: 2rem; font-weight: normal; }
            .card__excerpt { font-size: 1.15rem; }
            .card__more {
                color: #fff;
                margin-top: 1rem;
                flex-direction: column;
                justify-content: unset;
            }
            .card__read {
                margin-top: 1rem;
                // font-size: 1rem;
                font-weight: 700;
                text-transform: uppercase;
                border: 1px solid #fff;
                border-radius: 2rem;
                padding: .25rem 1rem;
            }
        }
    }
    
   }


  
    /* set card for button  */
    // &.--button {
    //     user-select: none;
    //     padding: 1rem var(--global--spacing-vertical);
    //     margin: 0;
    //     transition: 600ms all $smooth;        
    //     .card__inner {
    //         flex-direction: column;
    //         flex-wrap: unset;
    //         background-color: var(--wp--preset--color--grey-50);
    //         border-radius: 1rem 0 1rem 0;
    //         padding: 1rem 2rem;
    //     }
    //     .card__title { 
    //         padding:.5rem 3rem 0rem 0;
    //     }
    //     .card__excerpt {
    //         font-size: .9rem;
    //         color: var(--wp--preset--color--black)
    //     } 
    //     &:hover {
    //         text-decoration: none !important;
    //         .card__title { color: var(--wp--preset--color--secondary); }
    //         .card__inner { filter: drop-shadow(0 .5rem .5rem rgba(0,0,0,0.3)) }
    //     }
    // }  


    /* set card for job */
    &.--job {
        padding: .5rem 1rem;
          &:hover {
            
              text-decoration: none !important;
            .card__inner { 
                background-color:var(--wp--preset--color--primary);
                & > * { color: #fff; }
                svg { fill: #fff;}
            }
        }
        .card__inner {
            flex-direction: row;
            align-items: center;
            flex: 1 1 100%;
            padding: .5rem 1rem;
            border: 1px solid var(--wp--preset--color--grey-700);
            background-color: var(--wp--preset--color--grey-100);
            color: var(--wp--preset--color--black);
            border-radius: 8px;
            gap: 1rem;
            position: relative;
        }
        .job__date { 
            // flex: 0 0 100px;
            color: var(--wp--preset--color--black);
            font-size: .8rem;
        }
        .job__title { 
            // flex: 1 1 auto;
            font-weight: 700;
        }
        .job__place { 
            // flex: 0 0 100px;
            font-size: .8rem;
            color: var(--wp--preset--color--secondary);
        }
        .job__contract { 
            // flex: 0 0 100px;
            font-weight: 700;
            color: var(--wp--preset--color--secondary);
        }
        svg { 
            position: absolute; top:calc(50% - .5rem); right: 1rem;
            fill: var(--wp--preset--color--secondary);
        }

        @include breakpoint-is(mobile) {
            .card__inner { height: 40px; }
            .job__date { order:1; }
            .job__place { order:2; flex:1 0 auto; }
            .job__contract { order:3; flex:0 0 auto; } 
            .job__title { 
                order:5; flex: 0 0 100%;
            }
        }
        
    }

   
    // &.--ressource {
    //     height: 4rem;
    //     background: unset;
    //     border-bottom: 1px solid var(--wp--preset--color--grey-50);
    //     margin-bottom: 0 !important;
    //     &:hover {
    //         z-index: 999;
    //         .card__infos { text-decoration: none !important; }
    //     }
    //     .card__infos {
    //         flex-direction: row;
    //         align-content: space-around;
    //         align-items: center;

    //         font-size: .8rem;
    //         line-height: 1.25;
    //         color: #000;
    //         padding: .5rem 1rem;

    //         .card__ressource_type { 
    //             flex: 0 0 15%; 
    //             display: flex;
    //             align-items: center;
    //             svg { margin-right: .5rem;}
    //         }
    //         .card__title { 
    //             flex: 0 1 100%; 
    //             font-weight: unset;
    //             color: #000;
    //         }
    //         .card__ressource_size { flex: 0 0 72px; }
    //         .card__date { flex: 0 0 20%; }
    //     }
    //     @include breakpoint-is(tablet-down) {
    //         .card__infos { padding: .5rem 0;}
    //     }
    // }
}

//
.grid--head {
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid var(--wp--preset--color--primary-500, #307ABE);
    & > * {
        padding: 0 0 .5rem 0;
        font-size: .8rem;
        font-weight: 700;
        color: var(--wp--preset--color--grey-500);
        &:nth-child(1) { flex: 0 0 15%; }
        &:nth-child(2) { flex: 0 1 100%; }
        &:nth-child(3) { flex: 0 0 72px; }
        &:nth-child(4) { flex: 0 0 20%; }
    }
}