
.yo-block-filters {
	margin-bottom: 1rem;
	display: flex;
	
	&[js-fn] li {
		cursor: pointer;
		a { pointer-events: none; }
	}
	
	.btn--filters-reset {
		display: none;
		font-size: .8rem;
		font-weight: 700;
		color:var(--wp--preset--color--grey);
		padding: 0 1rem 0 1rem;
		transform: translateY(7px);
		text-decoration: none;
		user-select: none;
		&.--disabled { 
			pointer-events: none;
			cursor: default;
			opacity: .5;
		}
		@include breakpoint-is(laptop-down) {
			transform: translateY(9px);
		}
	}

	svg { fill: #515152; width: 1rem;}

	.filters_filters {
		font-size: .8rem;
		color: var(--wp--preset--color--grey);
		font-weight: 700;
		margin: 0 .5rem;
		// transform: translateY(-5px);
		//
		display: flex;
		align-items: center;
		//
		.icon { margin-left: .5rem;}
		//
		@include breakpoint-is(laptop-down) { 
			margin-right: 0rem;
			label { display: none !important;}
		}
	}
}


.filter__box {
	display: flex;
	align-items: center;
	&.--dropdown {
		&:not(:last-child) { margin-right: 1rem;}
		position: relative;
		width: fit-content;
		
		.filter__label { 
			user-select: none;
			cursor: pointer;
			margin: 0;
			padding: .5rem 1.5rem .5rem 1rem;
			text-align: center;
			position: relative;
			color: var(--wp--preset--color--grey);
			border: 1px solid var(--wp--preset--color--grey-700);
			border-radius: 8px;
			// &:before {
			// 	@include pseudo-block(100%, 2px, var(--wp--preset--color--grey-700) ); 
			// 	top: unset; bottom: 0;
			// 	transform-origin: top left; transform: scaleX(0);
			// 	transition: 300ms all $smooth;
			// }
			transition: 400ms all $smooth;
			&:after {
				--w : 4px;
				@include pseudo-block(0, 0); 
				top:12px; left:unset; right:.5rem;
				border-left: var(--w) solid transparent;
				border-right: var(--w) solid transparent;
				border-top: var(--w) solid var(--wp--preset--color--grey);
				transform-origin: center;
				transition: 400ms all $smooth;
				@include breakpoint-is(laptop-down) { top:9px; }
			}
			&:has( .filters) { background-color: rebeccapurple;}
		}
		.filters { 
			width : fit-content !important;	
			clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
			transition: 400ms all $smooth;
			pointer-events: none;
			//
			position: absolute; z-index: 999;
			// top:calc(1.5rem + 2px); 
			left:0; top:-4px;
			width: 100%;
			display: flex;
			flex-direction: column;
			background-color: #fff;
			border-radius: 8px;
			padding: .5rem;
			border: 1px solid var(--wp--preset--color--primary);
			//
			li {
				white-space: nowrap;
				// &.--selected { pointer-events: none; }
			}
		}

		&:hover {
			.filter__label:before { transform: unset !important; }
		}
		&.--selected {
			.filter__label {
				padding-right: 3rem;
				&:before { 
					content:'1';
					color: #000;
					background-color: var(--wp--preset--color--grey-700); 
					padding:.25rem .35rem; font-size: .7rem; border-radius: 4px;
					position: absolute; top:4px; right: 1.5rem;
				}
			}
		}
		&.--open {
			.filter__label {
				&:after { transform: rotate(180deg);}
			}
			.filters { 
				opacity: unset;
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
				pointer-events: unset;
			}
		}
	}
}

.grid {
	transition: 400ms all $smooth;
	&.--disabled {
		pointer-events: none;
		filter: grayscale(1);
	}
}


.filter__label {
	font-size: .8rem;
	line-height: 1;
	margin-right: 1rem;
	font-weight: 700;
	color: var(--wp--preset--color--grey-700);
}


ul.filters {
	display: flex;
	padding: 0;
	margin: 0 0 1rem 0;
	
	&.--disabled { pointer-events: none; }
	&.--multiple li { pointer-events: unset !important;}

	@include breakpoint-is(mobile-only) {
		flex-wrap: wrap;
		& > * { 
			flex: 1 1 calc(50% - 1rem); 
			margin: 0 .5rem 1rem 0 !important; 
		}
	}

	li {
		list-style: none;
		margin: 0 .5rem 0 0 !important;
		padding: .5rem .5rem;
		user-select: none;
		position: relative;
		font-weight: 400;
		font-size: .8rem;
		margin: 0;
		&:before, &:after {
			@include pseudo-block(100%, 3px, #E8E8E8 );
			top:unset; bottom: 0;
		}
		&:after {
			background-color: var(--wp--preset--color--primary-900);
			transition: 500ms all $bounce;
			transform-origin: top left;
			transform: scaleX(0);
		}
		&.--selected {
			a { color: var(--wp--preset--color--primary-900) !important}
			&:after { transform: unset; }
		}
		
		&:hover:not(.--selected) {
			a { color:var(--wp--preset--color--primary-900) !important; }
		}
		a.filter__link { 
			// font-size: .9rem;
			color : var(--wp--preset--color--black);
			font-weight: 700 !important; 
			text-decoration: none !important;
			transition: 300ms all $smooth;
			sup {
				font-size: .7rem;
				display: none;
			}
		}
	}
}


/* **************************************************************
No post
************************************************************** */
.block--btn-more { 
	&.--hidden { display: none; }
}

/* **************************************************************
No post
************************************************************** */
.block--no-posts { 
	&.--hidden { display: none; }
}

/* **************************************************************
Sprite Loading...
************************************************************** */

.sprite__loading {
	// margin-top: 1.5rem;
	display: none;
	opacity: 0;
	text-align: center;
	position: absolute; z-index: 23;
	transform: translateY(-4px);

	&.--is-loading { 
		opacity: unset;
	}
	& > * {
		display: inline-block;
		width: 10px; height: 10px;
		border-radius: 50%;
		transform-origin: center;
		&:nth-child(1) { background-color: var(--wp--preset--color--primary-light); animation: wait 1s .1s infinite; }
		&:nth-child(2) { background-color: var(--wp--preset--color--secondary-light); animation: wait 1s .5s infinite;}
	}
	@keyframes wait {
	  0%    { transform: scale(1);}
	  40%   { transform: scale(.2);}
	  100%  { transform: scale(1);}
	}
  }