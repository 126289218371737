.entry-content {

	ul,
	ol {
		margin: 0 0 .5rem 0;
		padding-left: calc( var(--global--spacing-horizontal) / 2 );

		// Utility classes
		&.aligncenter {
			list-style-position: inside;
			padding: 0;
		}

		&.alignright {
			list-style-position: inside;
			text-align: right;
			padding: 0;
		}
	}

	// ul:not(.filter-terms):not(.--reset):not(.breadcrumbs){
	ul:not([class]){
		li {
			list-style: none;
			position: relative;
			&:before {
				@include pseudo-block(5px, 5px, var(--wp--preset--color--primary));
				border-radius: 50%;
				transform: translate(-12px, 10px);
			}
		}	
	}
	
	ol {
		list-style-type: decimal;

		ul {
			list-style-type: circle;
		}
	}

	dt {
		font-family: var(--definition-term--font-family);
		font-weight: bold;
	}

	dd {
		margin: 0;
		padding-left: calc(2 * var(--global--spacing-horizontal));
	}

}

//Customize list 
ul.is-style-plus {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 2rem 0 1rem 0;

	& > * {
		flex: 0 0 50%;
	}
	ul {
		padding: 0;
		counter-reset: my-counter;
	}
	li {
		list-style: none;
		margin: 0;
	}

	& > li {
		
		font-size: 1.125rem;
		position: relative;
		padding:0 4rem 3rem 3.5rem;
		counter-increment: my-counter;
		
		&:before {
			content: '+';
			background-color: var(--wp--preset--color--secondary);
			color:var(--wp--preset--color--primary);;
			width: 40px; height: 40px;
			border-radius: 50%;
			font-size: 2rem;
			line-height: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute; top:0; left:0;
		}
		&:after {
			content: "0" counter(my-counter);
			color:var(--wp--preset--color--grey-200);
			font-size: 4.5rem;
			line-height: 1;
			position: absolute; top:50px; left:-3rem;
		}

		& > ul { 
			margin:1rem .5rem 2rem -2.5rem !important;
			& > li {
				list-style-type: disc;
				margin-left: 1rem;
				&:first-child:last-child { list-style-type: none; margin-left: 0; }

				//
				ul {
					margin-left: 1rem;
					li { list-style: circle;}
				}
			}
		}
		li {
			font-size: 1rem;
			color: var(--color-text);
		}
	}

	@include breakpoint-is(laptop-down) {
		& > * {
			flex: 0 0 100%;
			padding-right: 0 !important;
		}
		& > li:after { font-size: 2.5rem; left: 0; }
	}
	
}