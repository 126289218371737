// Responsive breakpoints mixin
@mixin add_variables( $view: frontend ) {

	@if frontend == $view {

		:root {
			@content;
		}
	}

	@if editor == $view {

		:root,
		body {
			@content;
		}
	}
}

// Add delay 
@mixin add_delay($offset: 200ms, $delay: 0, $count: 10, $type: transition) {
    @for $i from 1 through $count {
        $time: $delay + ( 100 - $i*10 ) / 100 * (($i - 1) * $offset);
        &:nth-child(#{$i}) { #{$type}-delay: $time; }
    }
}

// Button style
// - Applies button styles to blocks and elements that share them.
@mixin button-style() {
	cursor: pointer;
	font-family: var(--button--font-family);
	font-size: var(--button--font-size);
	font-weight: 700;
	// text-transform: uppercase;
	text-decoration: none;
	border: 1px solid var(--local--color-background, transparent);
	user-select: none;
	text-align: left;
	opacity: 1;
	position: relative;
	transition: 500ms all $smooth;
	//
	padding: 0 1.5rem;
	height: 40px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	// Standard Button Color Relationship Logic
	// &:not(:hover):not(:active) {

		// // Text colors
		// &:not(.has-text-color) {
		// 	color: #fff;;

		// 	// Nested
		// 	.has-background & {
		// 		color: var(--local--color-background, #fff);

		// 		&.has-background {
		// 			color: var(--color-text);
		// 		}
		// 	}
		// }

		// // Background-colors
		// &:not(.has-background) {
		// 	background-color: var(--wp--prese t--color--primary);

		// 	// Nested
		// 	.has-background & {
		// 		background-color: var(--local--color-primary, var(--color-heading));
		// 	}
		// }
	// }

	// Hover Button color should match parent element foreground color
	// &:hover,
	// &:active {
	// 	color: inherit;		
	// }

	// Focus Button outline color should always match the current text color
	// &:focus {
	// }

	// Disabled Button colors
	&:disabled {
		filter: saturate(0);
		pointer-events: none;
	}
}

@mixin innerblock-margin-clear($container) {

	// Clear the top margin for the first-child.
	> #{$container} > *:first-child {
		margin-top: 0;
	}

	// Last child that is not the appender.
	> #{$container} > *:last-child:not(.block-list-appender) {
		margin-bottom: 0;
	}

	// When selected, the last item becomes the second last because of the appender.
	&.has-child-selected > #{$container} > *:nth-last-child(2),
	&.is-selected > #{$container} > *:nth-last-child(2) {
		margin-bottom: 0;
	}
}


//
//
//
// Object-fit and fallback with polyfill
@mixin object-fit($value: cover) {
	object-fit: $value;
	font-family: 'object-fit: '+$value+';';    // object-fit-images polyfill
	width: 100%; height:100%;
  } 
  
// Pseudo block solid in absolute position
  @mixin pseudo-block($width, $height, $bg-color:null) {
	content: '';
	position: absolute;
	width: $width;
	height: $height;
	top:0; left:0;
	@if ($bg-color) {
	  background-color: $bg-color;
	}
  }
  
// Create aspect-ratio pseudo-element pusher in container
  @mixin aspect-ratio($ratio) {
	position: relative;
	&:before {
	  content: '';
	  display: block;
	  padding-top: $ratio;
	}
  } 


//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution
/// @example
///   h1 { @include responsive-type(20px, 48px); }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

  @mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $line-height:1.2,  $lower-range: 420px, $upper-range: 1680px) {

	font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
	line-height: $line-height;
  
	@media screen and (max-width: $lower-range) {
	  font-size: $min-font-size;
	}
	@media screen and (min-width: $upper-range){
	  font-size: $max-font-size;
	}
  }