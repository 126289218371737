.wp-block-image {
	
	figcaption {
		color: var(--wp--preset--color--black);
		font-size:1 var(--global--font-size-xs);
		line-height: var(--global--line-height-body);
		margin-top: calc(0.5 * var(--global--spacing-unit));
		margin-bottom: var(--global--spacing-unit);
		text-align: center;
	}

	&.alignright {
		margin-left: var(--global--spacing-horizontal);
		float: right;
	}
	
	// &.aligncenter {}

	.alignleft {
		margin-right: var(--global--spacing-horizontal);
	}

	a:focus img {
		outline-offset: 2px;
	}

	&.is-style-gradient {
		position: relative;
		border-radius: 10px;
		overflow: hidden;
		&:after {
			@include pseudo-block(100%, 100px);
			top:unset; bottom: 0;
			background: linear-gradient(0deg, var(--wp--preset--color--primary) 0%, rgba(255, 255, 255, 0) 100%);
			// background: linear-gradient(32.15deg, rgba(128, 187, 43, 0.4) 4%, rgba(21, 149, 63, 0.32) 20%, rgba(21, 149, 63, 0) 50%), radial-gradient(155.26% 115.87% at 70.62% 25%, rgba(21, 149, 63, 0) 0%, rgba(21, 149, 63, 0.08) 24%, rgba(5, 100, 32, 0.32) 64%, rgba(5, 100, 32, 0.4) 100%), 
		}
	}
}

// Remove vertical margins from image block wrappers when floated
.entry-content > *[class="wp-block-image"],
.entry-content [class*="inner-container"] > *[class="wp-block-image"] {
	margin-top: 0;
	margin-bottom: 0;

	// Remove top margins from the following element when previous image block is floated
	+ * {
		margin-top: 0;
	}
}

// Block Styles
.wp-block-image.is-style-rounded img {
	border-radius: 10px;
}


.entry-content {

	> .wp-block-image {

		> .alignleft,
		> .alignright {
			@include breakpoint-is(mobile) {
				max-width: 50%;
			}
			@include breakpoint-is(mobile-only) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
