.yo-block-download-list {
    
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        li {
            flex: 0 0 calc(33.33% - 2rem);
            background-color: #fff;
            border: 1px solid var(--wp--preset--color--grey-100);
            border-radius: 4px 4px 0 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .download_title {
                padding: .5rem 1rem;
                color: #fff;
                font-weight: 700;
                background-color: var(--wp--preset--color--secondary);
                display: flex;
                align-items: center;
                svg { 
                    fill: #fff; 
                    margin-right: .5rem; 
                    width: 1.5rem;
                }
            }
            .download_metas {
                font-size: .8rem;
                padding: 1rem 1rem;
                display: flex;
                flex-direction: column;
            }
            a {
                align-self: flex-start;
                color: var(--wp--preset--color--primary);
                font-weight: 700;
                font-size: .8rem;
                text-transform: uppercase;
                padding: .25rem .5rem;
                display: flex;
                align-items: center;
                border: 1px solid var(--wp--preset--color--primary);
                border-radius: 1rem;
                text-decoration: unset !important;
                margin: 0 0 1rem 1rem;
                svg { 
                    fill: var(--wp--preset--color--primary); 
                    margin-right: .5rem; 
                }
                &:hover {
                    background-color: var(--wp--preset--color--primary);
                    color: #fff;
                    svg { fill: #fff;}
                }
            }
        }
        @include breakpoint-is(tablet-down) {
            li { flex: 0 0 calc(50% - 2rem);}
        }
        
    }
}