/* Over here, place any elements that do not need to have their own file. */
b,
strong {
	font-weight: 800;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

pre {
	white-space: pre;
	overflow-x: auto;
}
