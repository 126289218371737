
/* Link */ 

// .link {
// 	font-family: var(--font-base);
// 	display: inline-block;
// 	text-decoration: none;
// 	position: relative; z-index: 5;
// 	padding: .2rem 0;
// 	color:#000;
// 	font-size: 1rem;
// 	line-height: 1;
// 	--local-color : inherit; 

// 	&:not(:last-child) { margin-right: 2rem;}

// 	&.--green 	{ --local-color : var(--global--color-olive); }
// 	&.--yellow 	{ --local-color : var(--global--color-yellow); }
// 	&.--solid 	{

// 		padding: .5rem 1rem;
// 		.icon { fill : #000; }
// 		&:after { height: 100%; z-index: -1; }
// 		&:hover { 
// 			color:#000;
// 			&:after { width: 3px; transform: unset; }
// 		}
// 	}

// 	&:after {
// 		@include pseudo-block(100%, 2px, var(--local-color));
// 		top:unset; bottom: 0;
// 		transform-origin: top left;
// 		transition: 300ms all $smooth;
// 	}
// 	.icon { 
// 		margin-right: .5rem;
// 		fill : var(--local-color);
// 	}

// 	&:hover {
// 		// color : var(--local-color);
// 		&:before, .icon { animation: jump $bounce 1000ms 200ms forwards; }
// 		&:after { transform: scaleX(0); }
// 	}

// 	@keyframes jump {
// 		0% 		{ transform: translateY(0); }
// 		20% 	{ transform: translateY(-10px); }
// 		60% 	{ transform: translateY(0px); }
// 	}

// 	//theses classes are injected via admin
// 	&.--add-cheese {
// 		padding-left: 2rem;
// 		&:before { 
// 			@include pseudo-block(24px, 24px);
// 			top:0px; left:0;
// 			background-position: center;
// 			background-repeat: no-repeat;
// 			background-image: url(../medias/icon-cheese.svg);
// 			filter: invert(1);
// 			@include breakpoint-is(desktop-down) { top:0; transform: scale(.8); }
// 		}
// 	}
// } 



/* Icons */ 
.icon {
	width: 32px; 
	height: 32px;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
	vertical-align: top;
	display: inline-block;

	fill: #000;

	&.--primary 	{ fill:var(--wp--preset--color--primary);}
	&.--secondary 	{ fill:var(--wp--preset--color--secondary);}
	&.--tertiary 	{ fill:var(--wp--preset--color--tertiary);}
	&.--white 		{ fill:#fff;}
	&.--small 		{ width: 16px; height: 16px;}
	&.--big 		{ width: 48px; height: 48px;}
	&.--fat 		{ width: 48px; height: 48px;}

	// &.--logo 		{ width: 170px; height: 120px;}
}

.icon__box {
	--local-color : var(--global--color-yellow);
	width: 56px; height: 56px;
	border-radius: 50%;
	border: 1px solid var(--local-color);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative; z-index: 2;
	svg {
		transition: 700ms all $smooth;
	}
	&:before {
		@include pseudo-block(100%, 100%, var(--local-color));
		border-radius: 50%;
		z-index: -1;
		transition: 500ms all $smooth;
		transform: scale3d(.9, .9, .9);
		opacity: 0;
	}
	&:not(:last-child) { margin-right: .5rem;}
	&:hover {
		&::before { transform: unset; opacity: unset;}
		svg { fill: #000 !important; transform: scale3d(1.1, 1.1, 1.1);}
	}
	
	&.--white {
		--local-color : #fff;
		svg { fill:#fff;}
	}
	&.--olive {
		--local-color : var(--global--color-olive);
	}
	&.--small {
		width: 24px; height: 24px;
	}
}