
 /*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
    &.--dots-left  .flickity-page-dots { justify-content: flex-start; }
    &.--dots-right .flickity-page-dots { justify-content: flex-end; }
    &.--dots-white {
      .dot {
        border-color: #fff;
        &:before { background-color: #fff;}
      }
    }
  }
  
  .flickity-enabled:focus { outline: none; }
  
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* remove overflow */
  .flickity-enabled.--no-overflow {
    .flickity-viewport { overflow: unset;}
  }
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-btn {
    cursor: pointer;
    &[disabled=true] {
      opacity: 0.3;
      filter: grayscale(1);
      cursor: auto;
      pointer-events: none;
    }
  }
  
  .flickity-button-icon {
    fill: currentColor;
  }

  
 
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  .flickity-rtl .flickity-page-dots { direction: rtl; }
  
  .flickity-page-dots {
    position: absolute;
    bottom: -1.75rem;
    left: 0;
    margin: 0 auto !important;
    padding: 0 !important;
    list-style: none;
    line-height: 1;
    display: flex;
    justify-content: flex-start;
    --local-color : var(--wp--preset--color--primary-50);
    width: calc(100% - 5.5rem);
    
    .dot {
      display: inline-block;
      position: relative;
      margin: 0 .5rem 0 0;
      width: 1rem; 
      height: 2px;
      border-radius: 2px;
      background-color: var(--local-color);
      
      transition: 200ms all ease-out;
      cursor: pointer;
  
      &:not(.dot.is-selected):hover {
        &:before {
          transform: scale3d(.7, .7, .7);
          opacity: 1;
        }
      }

      &.is-selected {
        // &:before { 
        //   opacity: 1; 
        //   transform: scale3d(1, 1, 1);
        //   background-color: var(--local-color);
        // }
        width: 4rem;
        background-color: var(--wp--preset--color--secondary);
        cursor: default;
      }
    }
  }
  

/* **************************************************************
Button for sliddddinnnng....
************************************************************** */
  
.flickity-button {
  width:2rem; height: 2rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  svg { 
    fill:var(--wp--preset--color--primary); 
    transform: scale(.6);
  }
  
  position: absolute; z-index: 1333;
  // top:calc( 50% - 38px/2 );
  bottom: -2.5rem;
  &.previous  { right:2.5rem;}
  &.next      { right:0rem;}
  // @include breakpoint-is(tablet-down) {
  //   bottom: -48px;
  //   &.previous  { left:0;}
  //   &.next      { left:3rem;}
  // }

  &[disabled] { 
    cursor: default;
    pointer-events: none;
    filter: grayscale(1);
    opacity: .4;
  }
  
  &:not([disabled="true"]):hover {
    svg { fill:var(--wp--preset--color--secondary); }
    // &.previous svg { transform:translateX(-3px)}
    // &.next svg { transform:translateX(3px)}
  }
  
  svg { transition: 300ms all ease-out;}

}

/* **************************************************************
Hide command if only one slide
************************************************************** */
.flickity-page-dots {
  li:first-child:last-child { display: none; }
}
.flickity-button {
  &[disabled]:has( + &[disabled]) { display: none;}   // remove previous
  &[disabled] + &[disabled] { display: none;}         // remove next
}

/* **************************************************************
Fadding....
************************************************************** */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.flickity-enabled.is-fade .slide {
  opacity: 0 !important;

  &:not(.is-selected){
      animation: fadeOut ease 0.2s;
  }

  &.is-selected {
      z-index: 99;
      opacity: 1 !important;
      animation: fadeIn ease 0.2s;
  }
}