// Gutenberg Font-size utility classes
:root {

	.is-extra-small-text,
	.has-extra-small-font-size {
		font-size: var(--global--font-size-xs);
	}

	.is-small-text,
	.has-small-font-size {
		font-size: var(--global--font-size-sm);
	}

	.is-regular-text,
	.has-regular-font-size,
	.is-normal-font-size,
	.has-normal-font-size,
	.has-medium-font-size {
		font-size: var(--global--font-size-base);
	}

	.is-large-text,
	.has-large-font-size {
		font-size: var(--global--font-size-lg);
		line-height: calc(var(--global--line-height-heading)*1.25);
	}

	.is-larger-text,
	.has-larger-font-size,
	.is-extra-large-text,
	.has-extra-large-font-size {
		font-size: var(--global--font-size-xl);
		line-height: calc(var(--global--line-height-heading)*1.25);
	}

	// .is-huge-text,
	// .has-huge-font-size {
	// 	font-size: var(--global--font-size-xxl);
	// 	line-height: calc(var(--global--line-height-heading)*1.25);
	// }

	// .is-gigantic-text,
	// .has-gigantic-font-size {
	// 	font-size: var(--global--font-size-xxxl);
	// 	line-height: calc(var(--global--line-height-heading)*1.25);
	// }
}
