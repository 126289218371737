/*
Adding print support. The print styles are based on the the great work of
Andreas Hecht in https://www.jotform.com/blog/css-perfect-print-stylesheet-98272/.
*/

@media print {

	/* Margins & paddings */
	body.single-cheese {
		// padding:33rem;
	}
	@page {
		// margin: 2cm;
	}

	/* Visibility */
	#site-navigation,
	.site-footer-nav, .site-footer__alt {
		display: none !important;
	}

	
	/* Fonts */
	body {
		line-height: 1.3;
		background: #fff !important;
		color: #000 !important;
	}

	// Override color settings that changes the text to white.
	.has-background-dark * {
		color: #000 !important;
	}

	h1 {
		font-size: 22pt;
		font-weight: bold;
	}

	h2,
	h3,
	h4,
	.has-regular-font-size,
	.has-large-font-size,
	h2.author-title,
	p.author-bio,
	.comments-title,
	.archive-description {
		font-size: 14pt;
		margin-top: 25px;
	}

	.comment-meta,
	.comment-meta .comment-author .fn {
		font-size: 13pt;
	}

	/* Page breaks */

	a {
		page-break-inside: avoid;
	}

	blockquote {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}

	img {
		page-break-inside: avoid;
		page-break-after: avoid;
	}

	table,
	pre,
	figure {
		page-break-inside: avoid;
	}

	ul,
	ol,
	dl {
		page-break-before: avoid;
	}

	  
}
