.gform_title {
    display: none;
    @extend .h3
}

.gform_required_legend {
    display: none;
}

.gsection_title {
    margin:1rem 0 0 0 !important;
}

.gform_wrapper.gravity-theme .gform_fields {
    grid-column-gap: 0 !important;
    grid-row-gap: 0 !important;
}

// custom required text
.gfield_required {
    // .gfield_required_text { opacity: 0; }
    &.gfield_required_asterisk {
        font-size: 1.25rem !important;
        color: #D68100 !important;
        line-height: 0;
    }
}

// customize field
// .gfield--type-radio {
//     border-radius: 0px;
//     border:11px solid var(--wp--preset--color--secondary-100) !important;
//     // &:not(:first-child) { margin-top: -1px;}
// }

.gfield:not(.gfield--type-html), .ginput_complex span {
    border-radius: 0px;
    border-color: var(--wp--preset--color--secondary-100);
    border-width: 1px 2px 1px 2px;
    border-style: solid;
                // border:1px solid var(--wp--preset--color--secondary-100);
                // &:not(:first-child) { margin-top: -1px;}
                // &:first-child { transform: translateY(1px);}
    
    background-color: #fff;
    padding: .5rem;
    align-self: flex-start;
    transition: 200ms all ease-out;
    input, textarea, select {
        width : 100% !important;
        background: unset;
        border: none;
        padding-left: 0 !important;
        &:focus { outline: none; };
        &[type=checkbox], &[type=radio] {
            accent-color : var(--wp--preset--color--primary);
        }
        // border:1px dotted burlywood;
    }
    
    //reduice width if large, medium or small
    &.gfield--width-full:has(input.medium), &.gfield--width-full:has(textarea.medium), 
    &.gfield--width-full:has(select.medium) { width: 50% !important;}
    &.gfield--width-full:has(input.small), &.gfield--width-full:has(textarea.small), 
    &.gfield--width-full:has(select.small) { width: 33% !important;}

    // special for grid
    &.gfield--width-half:nth-child(odd) { 
        // transform: translateX(-2px);
    }
    // &.gfield--width-full { width: calc(100% - 2px) !important;}
    // special for grid

    & >label, & >legend {
        color:var(--wp--preset--color--black);
        margin-bottom: 0 !important
    }
    ::placeholder { font-style: italic;}

    .gfield_description { padding: 0 !important; margin: 0 0 .5rem 0 !important;}

    &:focus-within:not(.--no-hover) {
        // background-color: #fff !important;
    }
    &.--filled:not(.--no-hover) { 
        label, legend { 
            color:var(--wp--preset--color--primary);
        }
        background-color: unset;
        // border-color: var(--wp--preset--color--grey-700) !important;
    }

    &:hover:not(.--no-hover):not(.gfield--type-checkbox):not(.gfield--type-address):not(.gfield--type-consent) {
        // box-shadow: 0px 0px 4px rgba(48, 122, 190, 0.48), 0px 0px 16px rgba(48, 122, 190, 0.24), inset 0px 0px 8px rgba(48, 122, 190, 0.56);
        border-color: var(--wp--preset--color--primary);
        background-color: #fff;
        z-index:9999;
    }
    
    &.gfield_error {
        border-color: red;
        label { color:unset !important; }
        input { border: unset !important;}
        .gfield_validation_message { display: none;}
    }
  
}

// Checkbox & radio
.gfield.gfield--type-checkbox, .gfield.gfield--type-radio {
    border:2px solid var(--wp--preset--color--secondary-100) !important;
    padding: 2rem .5rem 1rem .5rem !important;
    legend { 
        line-height: 0;
        transform: translateY(1rem);
        background: transparent !important;
        // display: inline !important
    }
    .ginput_container { padding-left: 1rem;}
    .gchoice {
        display: flex;
        label { color: var(--wp--preset--color--black) !important; }
        & > *:nth-child(1) { flex: 0 0 1rem;}
        & > *:nth-child(2) { padding-left: .5rem;}
    }
}

// Consent
.gfield.gfield--type-consent {
      // consent
      .ginput_container_consent {
        padding: 2rem 1rem 0 1rem !important;
        display: flex;
        align-items: start;
        & > *:nth-child(1) { 
            margin-top: .2rem !important;
            flex: 0 0 1.25rem;
        }
        & > *:nth-child(2) { 
            padding-left: .5rem;
            font-size: .8rem !important;
            color: var(--wp--preset--color--black) !important;
        }
        @include breakpoint-is(tablet-down) {
            input[type=checkbox] {
                width: 2rem !important;
            };
        }
    }
}

// Special FIELD ADDRESS
.ginput_complex {
    label { font-weight: 700; }
    span { margin-bottom: 0 !important;}
}

@include breakpoint-is(tablet-up) {
.gfield--type-address > legend { margin-left: .5rem !important;}
.ginput_complex {
    margin-left: 0 !important;
    margin-right: 0 !important;
    gap: 1rem;
        & > span { 
            flex: calc(50% - 1rem) !important; 
            &.address_zip,
            &.address_country { flex: calc(25% - 1rem) !important;  }
        }
    }
}

// UPLOAD
.ginput_container_fileupload {
    .gform_fileupload_rules {
        font-size: .8rem !important;
        font-style: italic;
    }
}

//SUBMIT
.gform_button {
    @include button-style();
    color: #fff !important;
    background: var(--wp--preset--color--primary);
    font-size: 1.125rem;
    height: 50px;
    border-radius: 25px;
    margin: 0 auto;
    text-transform: uppercase;
    padding: 0rem 2rem;
    letter-spacing: .1rem;
    &:hover {
        background-color: var(--wp--preset--color--secondary);
    }
}

// MESSAGE VALIDATION
.gform_validation_errors { display: none;}

// FIELD VALIDATION
// .gfield_validation_message {
//     padding: 0 .5rem !important;
//     font-size: .8rem !important;
//     border: unset !important;
//     background-color: unset !important;
//     font-style: italic;
// }