
// Footer
// @include breakpoint-is(desktop) {
// 	.site-footer__alt {
// 		padding : 0 var(--content-horizontal-padding);
// 		ul { 
// 			flex-direction: row;
// 			li:not(:first-child) { margin-left: 1rem}
// 		};
// 	}

// 	.site-infos {
// 		flex: 0 0 34%;
// 		padding-right: 2rem;
// 		p { opacity: .6;}
// 	}
	 
// }

.site-footer {
	background: linear-gradient(90deg, var(--wp--preset--color--primary) 0%, var(--wp--preset--color--secondary) 100% );
	color:#fff;
	padding-top: 3rem;
	padding-bottom: 1rem;
	position: relative;
	z-index: 90;

	@extend %responsive-alignfull-width;
	
	.foo-main, .foo-alt {
		max-width: 1110px; //$max_content_width;
		padding: 0 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 1rem auto;
	}

 
	// foo logo
	.foo-logo {
		flex: 0 0 40%;
		--foo-logo-width : 128px;
		flex-direction: row;
		display: flex;
		align-items: center;
		svg {
			width: var(--foo-logo-width);
			height: var(--foo-logo-width);
		} 
		.logo-baseline {
			padding-left: 2rem;
			font-size: 1.5rem;
			line-height: 1;
			text-align: right;
			strong { font-weight: 900; text-transform: uppercase;}
		}
	}
	
	.foo-menu {
		flex: 0 0 25%;
		ul {
			display: flex;
			flex-direction: column;
			li {
				padding:.5rem 0;
				a { text-transform: uppercase; }
			}
		}
	}

	.foo-social {
		padding: 0 2rem;
		align-self: center;
		ul { margin-bottom: 0 !important;}
		.wp-social-link {
			&:after {
				pointer-events: none;
				@include pseudo-block(100%, 100%);
				border-radius: 50%;
				border: 1px solid #fff;
				transition: 400ms all $smooth;
			}
			&:hover {
				svg { fill: #fff;}
				&:after { opacity: 0;}
			}
		}
	}
	.foo-contact {
		align-self: center;
	}

	ul {
		padding: 0; margin: 0;
		li { list-style: none; }
		&.menu {
			display: flex;
			li { margin:0 .5rem;}
			a { font-size: .8rem;}
		}
	}

	a:not(.wp-block-social-link-anchor) {
		color: inherit;
		transition: 300ms all ease-out;
		text-decoration: none;
		font-size: .875rem;
		&:link,
		&:visited,
		&:focus,
		&:active {
			color: inherit;
		}
	}

	.foo-alt {
		border-top: 1px solid rgba(255,255,255,.3);
		padding: 2rem 1rem;
		& > * { width: 100%;}
		ul {
			justify-content: space-between;
		}
	}


	@include breakpoint-is(tablet-down) {
		.foo-logo,
		.foo-contact { 
			flex: 0 0 100%;
			margin-bottom: 1rem;
		}
		.foo-logo { 
			justify-content: space-between;
			padding-right: 2rem;
		}
		.foo-menu,
		.foo-social { 
			flex: 0 0 50%;
			margin-bottom: 1rem;

			.wp-block-social-links { align-items: flex-end; }
		}

		.foo-contact .wp-block-buttons,
		.foo-alt ul {
			flex-direction: column;
			flex-wrap: wrap;
			align-items: center;
		}
		.logo-baseline {
			font-size: 1.5rem !important;
		}
	}
}
 



/* button to scroll up */
// .button--scroll-to-top {
// 	background-color: #fff;
// 	border: 1px solid var(--wp--preset--color--secondary) !important;
// 	border-radius: 50% !important;
// 	width : 48px !important; height: 48px !important;
// 	padding: 0 !important;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	position: fixed !important; z-index: 89;
// 	bottom: 2rem; right:2rem;
// 	opacity: 0; transform: scale(0);
// 	transition: 200ms opacity ease-out, 400ms transform $smooth;
// 	&.--enabled { opacity: unset; transform: unset;}
// 	svg { 
// 		fill: var(--wp--preset--color--secondary); 
// 		transform: scale(.70); transition: 500ms all $smooth; 
// 	}
// 	&:hover {
// 		opacity: 1 !important;
// 		border-color: #777 !important;
// 		svg { fill: #000; transform: scale(.70) translateY(-3px); }
// 	}

// 	@include breakpoint-is(mobile) {
// 		bottom:1rem; right: 1rem;
// 		transform: scale(.75) !important;
// 	}
// }
