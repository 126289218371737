.slider {
    &.--products {
        .flickity-slider { 
            display: flex;
            align-items: stretch;
        }
        .card { 
            width: 100%;
        }
        @include breakpoint-is(tablet-down) { margin-bottom: 2rem;}
    }
}


.yo-block-slider {

    & > .wp-block-group__inner-container { padding: 0 !important;}

    .slider {
        position: relative; 
        z-index: 1;
        width: 100%; 
        min-height: 300px;
        padding: 0 0;

        .flickity-viewport { 
            border-radius: 8px;
            overflow: hidden;
        }
     
        .slide {
            height: 400px;
            width: 100%; 
            // height: 100%;
            // @include aspect-ratio(66%);
        }

        &.--default {
            position: relative;
            a {
                text-decoration: none !important;
                display: inline-block;
                height: 100%; width: 100%;
            }
            .slide__img {
                margin: 0; padding: 0;
                width:100%; height: 100%;
                img {
                    @include object-fit(cover);
                }

            }
            .slide__infos {
                position: absolute; z-index: 3;
                width: 100%; height: 100%;
                top:0; left: 0;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                background: linear-gradient(180deg, rgba(0, 75, 122, 0.00) 0%, #004B7A 100%);
                @include breakpoint-is(tablet-down) { padding: 1rem;}
                
            }
            
            .slide__title { 
                font-size: 2rem;
                line-height: 1;
                margin-bottom: .5rem;
                font-weight: 800;
                color:#fff;
                max-width: 700px;
            }
            .slide__desc { 
                color:#fff;
                font-size: 1.1rem;
            }
            p {
                margin-top: 1rem;
                color:#fff;
                font-weight: 700;
            }
            // @include breakpoint-is(tablet-down) {
            //     .slide__infos {
                    
            //         border: 10px solid red !important;
            //     }
            // }
        }
    }
}