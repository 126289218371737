// Measure
// - The width of a line of text, in characters, is known as its measure.

header *,
main *,
footer * {
	max-width: var(--global--spacing-measure);
}

html,
body,
div,
header,
nav,
article,
figure,
hr,
main,
section,
footer {
	max-width: none;
}
