// /*
//  * text-underline-offset doesn't work in Chrome at all 👎
//  * But looks nice in Safari/Firefox, so let's keep it and
//  * maybe Chrome will support it soon.
//  */
a {
	cursor: pointer;
	color: var(--wp--preset--color--primary);  // var(--color-text);
	text-underline-offset: 3px;
	text-decoration-skip-ink: all;
}

a:hover {
	text-decoration-style: dotted;
	text-decoration-skip-ink: none;
}

// Change colors when the body background is white.
// .has-background-white & {
// 	background: rgba(0, 0, 0, .9);
// 	color: red;
// }

.entry-content a:not(.wp-block-button__link):not(.wp-block-file__button):not(.card)  {
	position: relative;
	// &:before {
	// 	@include pseudo-block(100%, 1px, #fff);
	// 	mix-blend-mode: exclusion;
	// 	top:unset; bottom: 0; z-index: -1;
	// 	transition: 400ms all $smooth;
	// }
	// &:hover{
	// 	color: #fff;
	// 	&:before { height: 100%;}
	// } 
	outline: 2px dotted transparent;
	text-decoration: underline 1px dotted currentColor;
	text-decoration-skip-ink: none;
	}

	a:where(:not(.wp-element-button)) {text-decoration: none !important;}
	a:where(:not(.wp-element-button)):hover {text-decoration: underline !important;}

// // Enforce the custom link color even if a custom background color has been set.
// // The extra specificity here is required to override the background color styles.
.has-background {
	// Target both current level and nested block.
	.has-link-color a,
	&.has-link-color a {
		color: var(--wp--style--color--link, var(--wp--preset--color--primary));
	}
}
