/**
* Responsive breakpoints mixin
* Required Variables
*/

$default_width		: 980px;	// align: -
$wide_width			: 1240px;	// align: alignwide
$max_content_width	: 1680px;	// align: alignfull

$breakpoint_sm		: 480px;	//mobile
$breakpoint_md		: 768px;	//tablet
$breakpoint_lg		: 1024px;	//laptop
$breakpoint_xl		: 1200px;	//desktop
$breakpoint_xxl		: 1680px;	//wide
 
// Responsive breakpoints mixin
@mixin breakpoint-is( $res ) {

	@if mobile == $res {
		@media only screen and (max-width: #{$breakpoint_sm - 1}) { @content;}
	}
	@if mobile-only == $res {
		@media only screen and (max-width: #{$breakpoint_sm - 1}) { @content; }
	}
	@if mobile-up == $res {
		@media only screen and (min-width: #{$breakpoint_sm}) { @content; }
}

	@if tablet-down == $res {
		@media only screen and (max-width: #{$breakpoint_md - 1}) { @content; }
	}
	@if tablet-only == $res {
		@media only screen and (min-width: #{$breakpoint_sm}) and (max-width: #{$breakpoint_md - 1}) { @content; }
	}
	@if tablet-up == $res {
		@media only screen and (min-width: #{$breakpoint_md}) { @content; }
	}

			// @if laptop-down-landscape == $res {
			// 	@media only screen and (orientation:landscape) and (max-width: #{$breakpoint_lg - 1}) { @content; }
			// }
	@if laptop-down == $res {
		@media only screen and (max-width: #{$breakpoint_lg - 1}) { @content; }
	}
	@if laptop-only == $res {
		@media only screen and (min-width: #{$breakpoint_md}) and (max-width: #{$breakpoint_lg - 1}) { @content; }
	}
	@if laptop-up == $res {
		@media only screen and (min-width: #{$breakpoint_lg}) { @content; }
	}

	@if desktop-down == $res {
		@media only screen and (max-width: #{$breakpoint_xl - 1}) { @content; }
	}
	@if desktop-only == $res {
		@media only screen and (min-width: #{$breakpoint_lg}) and (max-width: #{$breakpoint_xl - 1}) { @content; }
	}
	@if desktop-up == $res {
		@media only screen and (min-width: #{$breakpoint_xl}) { @content; }
	}

@if wide-down == $res {
	@media only screen and (max-width: #{$breakpoint_xxl - 1}) { @content; }
}
	@if wide-only == $res {
	@media only screen and (min-width: #{$breakpoint_xxl}) { @content; }
	} 
	@if wide-up == $res {
		@media only screen and (min-width: #{$breakpoint_xxl}) { @content; }
	}
}
 
/**
* Root Media Query Variables
*/
:root {
	--responsive--spacing-horizontal: calc(2 * var(--global--spacing-horizontal) );
	--responsive--aligndefault-width: calc(100vw - var(--responsive--spacing-horizontal));
	--responsive--alignwide-width: calc(100vw - var(--responsive--spacing-horizontal));
	--responsive--alignfull-width: 100%;
	--responsive--alignright-margin: var(--global--spacing-horizontal);
	--responsive--alignleft-margin: var(--global--spacing-horizontal);
	//
	--content-horizontal-padding : 2rem;
}
 
/**
* Responsive Styles
*/
 
@include breakpoint-is(mobile-only) {

	//  :root {
	// 	 --responsive--aligndefault-width: min(calc(100vw - 4 * var(--global--spacing-horizontal)), #{$default_width});
	// 	 --responsive--alignwide-width: calc(100vw - 4 * var(--global--spacing-horizontal));
	// 	 --responsive--alignright-margin: calc(0.5 * (100vw - var(--responsive--aligndefault-width)));
	// 	 --responsive--alignleft-margin: calc(0.5 * (100vw - var(--responsive--aligndefault-width)));
	// 	 --content-horizontal-padding : 1rem;
	//  }
}
 
@include breakpoint-is(tablet-down) {
	// body { border:2px solid green; }
}

@include breakpoint-is(tablet-down) {
	 
	:root {
		--global--font-size-base : 13px;
		--content-horizontal-padding : 2rem;
		--global--spacing-horizontal: 1rem;
		--responsive--aligndefault-width: min(calc(100vw - 2 * var(--global--spacing-horizontal)), #{$default_width});
		--responsive--alignwide-width: min(calc(100vw - 2 * var(--global--spacing-horizontal)), #{$max_content_width});
		//
		// --heading--font-size-h6		: calc( .25 * var(--global--font-size-xs));
		// --heading--font-size-h5		: calc( .25 * var(--global--font-size-sm));
		// --heading--font-size-h4		: calc( .25 * var(--global--font-size-md));
		--heading--font-size-h3		: calc( .75 * var(--global--font-size-lg));
		--heading--font-size-h2		: calc( .65 * var(--global--font-size-xl));
		--heading--font-size-h1		: calc( .75 * var(--global--font-size-xxl));
	}
}
 
@include breakpoint-is(desktop) {

	:root {
		--responsive--aligndefault-width: min(calc(100vw - 5 * var(--global--spacing-horizontal)), #{$default_width});
		--responsive--alignwide-width: min(calc(100vw - 5 * var(--global--spacing-horizontal)), #{$max_content_width});
		//
		--content-horizontal-padding : 4rem
	}
}
 
@include breakpoint-is(wide-only) {
	
	:root {
		--content-horizontal-padding : 2rem
	}
}
 
 
/**
* Extends
*/
%responsive-aligndefault-width {
	// max-width: var(--responsive--aligndefault-width);
	margin-left: auto;
	margin-right: auto;
}

%responsive-alignwide-width {
	max-width: var(--responsive--alignwide-width);
	margin-left: auto;
	margin-right: auto;
}

%responsive-alignfull-width-mobile {
	max-width: var(--responsive--alignfull-width);
	width: var(--responsive--alignfull-width);
	margin-left: auto;
	margin-right: auto;
}

@include breakpoint-is(mobile-only) {
	%responsive-alignfull-width {
		max-width: var(--responsive--alignfull-width);
		width: auto;
		margin-left: auto;
		margin-right: auto;
	}
}

%responsive-alignwide-width-nested {
	margin-left: auto;
	margin-right: auto;
	width: var(--responsive--alignwide-width);
	max-width: var(--responsive--alignfull-width);
}

%responsive-alignfull-width-nested {
	margin-left: auto;
	margin-right: auto;
	width: calc(var(--responsive--alignfull-width) - calc(2 * var(--responsive--spacing-horizontal)));
	max-width: var(--responsive--alignfull-width);
}
 
 @include breakpoint-is(desktop) {
	 %responsive-alignfull-width-nested {
		 margin-left: auto;
		 margin-right: auto;
		 width: calc(var(--responsive--alignfull-width) - calc(4 * var(--responsive--spacing-horizontal)));
		 max-width: var(--responsive--alignfull-width);
	 }
 }
 
 
%responsive-alignleft-mobile {

	/*rtl:ignore*/
	margin-left: 0;

	/*rtl:ignore*/
	margin-right: var(--responsive--spacing-horizontal);
}

@include breakpoint-is(mobile-only) {
	%responsive-alignleft {

		/*rtl:ignore*/
		margin-left: var(--responsive--alignleft-margin);

		/*rtl:ignore*/
		margin-right: var(--global--spacing-horizontal);
	}
}

%responsive-alignright-mobile {

	/*rtl:ignore*/
	margin-left: var(--responsive--spacing-horizontal);

	/*rtl:ignore*/
	margin-right: 0;
}

@include breakpoint-is(mobile-only) {
	%responsive-alignright {

		/*rtl:ignore*/
		margin-left: var(--global--spacing-horizontal);

		/*rtl:ignore*/
		margin-right: var(--responsive--alignright-margin);
	}
}

// Output
.default-max-width {
	@extend %responsive-aligndefault-width;
}

.wide-max-width {
	@extend %responsive-alignwide-width;
}

.full-max-width {
	@extend %responsive-alignfull-width;
}
 
 
 
/* **************************************************************
RWD STATUS
************************************************************** */
.rwd_status {
	position: fixed; z-index: 99999;
	top:0; left:0;
	background-color:deeppink;
	margin: 0; padding: 2px 5px;
	li { 
		list-style: none; 
		margin: 0; font-size: .8rem; line-height: 1rem;
		color: #fff !important; 
		font-weight: bold; text-transform: uppercase;
		display: none;
	//  @include breakpoint-is(mobile-only)		{ &.is-sm  { display: block;} }
	//  @include breakpoint-is(tablet-only) 	{ &.is-md  { display: block;} }
	//  @include breakpoint-is(laptop-only) 	{ &.is-lg  { display: block;} }
	//  @include breakpoint-is(desktop-only) 	{ &.is-lg  { display: block;} }
	//  @include breakpoint-is(wide-only)		{ &.is-xl { display: block;} }
	//  @include breakpoint-is(wide)			{ &.is-xxl { display: block;} }
	}
}